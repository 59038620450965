/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Field, Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import 'fetures/CompanyProfile/Components/AttributesForm/style.css';

const AttributesForm = ({ attributes }) => {
  const fieldRefs = useRef({});
  const { handleSubmit, setFieldValue, values } = useFormikContext();

  const [formStep, setFormStep] = useState(1);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const handleBackFormStep = () => {
    setFormStep((preFormStep) => preFormStep - 1);
  };

  const handleConfirmFormStep = async (isRequired, objectKey) => {
    setIsConfirmLoading(true);

    const attributeExists = values?.attributes?.[objectKey];
    const isLastStep = attributes.length === formStep;

    if ((isRequired && attributeExists) || !isRequired) {
      if (isLastStep) {
        await handleSubmit();

        setIsConfirmLoading(false);
      } else {
        setFormStep((prevStep) => prevStep + 1);
        setIsConfirmLoading(false);
      }
    } else {
      setIsConfirmLoading(false);
    }
  };

  useEffect(() => {
    const fieldRef = fieldRefs.current[formStep];
    if (fieldRef) fieldRef.focus();
  }, [attributes, fieldRefs, formStep]);

  return (
    <div className="cp_attributes_form-containers">
      <Form onSubmit={handleSubmit}>
        <div className="cp_attributes_form-main-container">
          <div className="cp_attributes_form-title-wrapper">
            <p className="cp_attributes_form-title">- SETUP COMPANY PROFILE</p>

            <div className="cp_attributes_form-progress-bar">
              <div
                className="cp_attributes_form-progress-bar-progress"
                style={{
                  width: `${(formStep * 100) / (attributes.length || 0)}%`,
                }}
              />
            </div>
          </div>

          {attributes &&
            attributes.map((attribute, index) => (
              <div
                className={`cp_attributes_form-body cp_attributes_form-step-${index + 1} ${formStep === index + 1 ? 'active' : ''}`}
                key={`cp_attributes_form-attribute${index}`}
              >
                <div className="cp_attributes_form-content">
                  <h4>{attribute?.title || ''}</h4>
                  <p>{attribute?.description || ''}</p>

                  <div className={`cp_attributes_form-element ${attribute?.inputType}`}>
                    {attribute?.inputType === 'dropdown' ? (
                      <Select
                        ref={(ref) => (fieldRefs.current[index + 1] = ref)}
                        name={`attributes[${attribute?.objectKey}]`}
                        options={
                          attribute?.values?.reduce((attributes, attribute) => {
                            attributes.push({
                              label: attribute,
                              value: attribute === '-' ? null : attribute,
                            });

                            return attributes;
                          }, []) || []
                        }
                        className="cp_attributes_form-rc-select"
                        classNamePrefix="cp_attributes_form-rc-select"
                        onChange={(option) =>
                          setFieldValue(`attributes[${attribute?.objectKey}]`, option ? option.value : null)
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();

                            if (
                              (values.attributes[attribute?.objectKey] && !attribute.isOptional) ||
                              attribute.isOptional
                            ) {
                              handleConfirmFormStep(!attribute?.isOptional, attribute?.objectKey);
                            } else {
                              const selectInstance = fieldRefs.current[index + 1];
                              if (selectInstance) {
                                selectInstance.props.onMenuOpen();
                              }
                            }
                          }
                        }}
                        placeholder="Click to select"
                        menuPortalTarget={document.body}
                        value={
                          values?.attributes?.[attribute?.objectKey]
                            ? {
                                label: values?.attributes?.[attribute?.objectKey],
                                value: values?.attributes?.[attribute?.objectKey],
                              }
                            : null
                        }
                      />
                    ) : attribute?.inputType === 'shortText' ? (
                      <Field name={`attributes[${attribute?.objectKey}]`}>
                        {({ field }) => (
                          <div>
                            <input
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleConfirmFormStep(!attribute?.isOptional, attribute?.objectKey);
                                }
                              }}
                              ref={(ref) => (fieldRefs.current[index + 1] = ref)}
                              className="cp_attributes_form-input-element"
                              type="text"
                              placeholder="Type your..."
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                    ) : attribute?.inputType === 'longText' ? (
                      <Field name={`attributes[${attribute?.objectKey}]`}>
                        {({ field }) => (
                          <div>
                            <textarea
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  handleConfirmFormStep(!attribute?.isOptional, attribute?.objectKey);
                                }
                              }}
                              ref={(ref) => (fieldRefs.current[index + 1] = ref)}
                              className="cp_attributes_form-textarea-element"
                              type="textarea"
                              placeholder="Type your..."
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                    ) : (
                      ''
                    )}

                    {attribute?.isOptional && (
                      <p className="cp_attributes_form-optional-label">
                        <i>Optional</i>
                      </p>
                    )}
                  </div>
                </div>

                <div className="cp_attributes_form-button-wrapper">
                  {index > 0 && (
                    <button
                      disabled={isConfirmLoading}
                      onClick={handleBackFormStep}
                      className="cp_attributes_form-button-skip"
                      type="button"
                    >
                      Back
                    </button>
                  )}

                  <button
                    disabled={isConfirmLoading}
                    onClick={() => handleConfirmFormStep(!attribute?.isOptional, attribute?.objectKey)}
                    className="cp_attributes_form-button-confirm"
                    type="button"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            ))}
        </div>
      </Form>
    </div>
  );
};

AttributesForm.defaultProps = {
  attributes: PropTypes.any,
};

AttributesForm.propTypes = {
  attributes: PropTypes.any,
};

export default AttributesForm;
