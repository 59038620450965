/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unreachable */
/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import {
  MdBackspace,
  MdDownload,
  MdFitScreen,
  MdFullscreenExit,
  MdImage,
  MdSave,
  MdZoomIn,
  MdZoomOut,
} from 'react-icons/md';
import Lottie from 'react-lottie';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import store from 'app/store';
import axios from 'axios';
// import { useMutation } from 'react-query';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY, routes, TOAST_CONFIG } from 'constant';
import * as go from 'gojs';
import { ReactDiagram, ReactOverview } from 'gojs-react';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { updateWorkspaceNodes } from 'reducers/workspaceSlice';
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';
import { TopNavigationBar } from 'components';
import {
  checkWorkspaceAccess,
  getMindMapApi,
  getMindMapExportApi,
  getMindMapResults,
  getMindMapResultsCombined,
  getMindMapResultsExport,
  updateWorkspaceApi,
} from 'api/mindMamp';
import { getUserSubscription } from 'api/payment';
import { catchBlock } from 'utils';
import mapMenuIcon from '../../assets/animations/map.json';
import animationMenu from '../../assets/animations/Menu.json';
import CommentModal from './Components/CommentModal';

import './style.css';

const MindMapEdit = () => {
  const commentModelRef = useRef();
  const miniMapIconRef = useRef();
  const $ = go.GraphObject.make;
  const { id } = useParams();
  const { pathname } = useLocation();
  const [workspaceId, setWorkspaceId] = useState(id);
  const [miniMapAnimationFlag, setMiniMapAnimationFlag] = useState(true);
  // const [initialState, setInitialState] = useState([]);
  const [miniMapView, setMiniMapView] = useState(false);
  const [showCommentModel, setShowCommentModel] = useState(false);
  const [showExportModel, setShowExportModel] = useState(false);
  const [showExportLoader, setShowExportLoader] = useState(false);
  const [observed, setObserved] = useState(null);
  const [scriptCounter, setScriptCounter] = useState(1);
  const [commentNodeData, setCommentNodeData] = useState();
  const [redirectSub, setRedirectSub] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const diagramRef = useRef('TestGraph');
  const navigate = useNavigate();
  const getCombinedMindMapResultsObj = useMutation(getMindMapResultsCombined);
  const getMindMapResultsExportObj = useMutation(getMindMapResultsExport);
  let diagram = null;
  const authToken = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  const socketRef = useRef(null);
  const isNodeDragging = useRef(null);
  const [disabledNodes, setDisabledNodes] = useState([]);
  const dispatch = useDispatch();

  const workspaceTitle = useRef(null);
  const [isTitleEditing, setIsTitleEditing] = useState(false);

  const handleSetWorkspaceTitleEditing = () => {
    setIsTitleEditing(true);
  };

  const handleBlurWorkspaceTitle = (e) => {
    if (e.target.value) {
      setIsTitleEditing(false);
      workspaceTitle.current = e.target.value;

      setTimeout(() => {
        save();
      }, [200]);
    }
  };

  const {
    mutate: fetchUserSub,
    isFetching: isFetchingUserSubscription,
    refetch: refetchUserSub,
  } = useQuery({
    queryKey: ['user-subscription-expire'],
    queryFn: getUserSubscription,
    enabled: !!(authToken && authToken !== ''),
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (
        data.data &&
        data.data.status !== 'active' &&
        data.data.status !== 'free_trial' &&
        data.data.status !== 'trialing'
      ) {
        setRedirectSub(true);
        if (data.data.status === 'expired') setRemainingDays(0);
      } else {
        setRedirectSub(false);
        setRemainingDays(data?.data?.remaining_days || null);
      }
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const { data: workSpaceAccess, isLoading: isLoadingWorkspaceAccess } = useQuery({
    queryKey: ['check-workspace-access', id],
    queryFn: checkWorkspaceAccess,
    enabled: !!id,
    onSuccess: (data) => {
      if (data?.data?.isAccess === false) {
        toast.error(data.message, TOAST_CONFIG);
        navigate(routes.dashBoard);
      }
    },
    onError: (error) => {
      catchBlock(error);
      if (error?.response?.data?.data?.isAccess === false) {
        navigate(routes.dashBoard);
      }
    },
  });

  const { mutate: savedWorkSpaceMutate, isLoading: isLoadingWorkspaceMutate } = useMutation(updateWorkspaceApi, {
    onSuccess: (response) => {
      const nodes = response?.data?.output ? JSON.parse(response?.data?.output).nodeDataArray : [];

      dispatch(updateWorkspaceNodes(nodes));
    },
    onError: (error) => {
      catchBlock(error);
    },
  });

  useEffect(() => {
    // miniMapIconRef?.current?.play(30, 120);
    // if (miniMapIconRef.current) {
    // }

    if (authToken && authToken !== '') refetchUserSub();

    const interval = setInterval(() => {
      setMiniMapAnimationFlag(false);
      setTimeout(() => {
        setMiniMapAnimationFlag(true);
      }, 1100);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const workSpaceId = workSpaceAccess?.data?.work_space?.id;
    if (!workSpaceId) return;

    const handleClickOutsideCommentModal = (event) => {
      if (commentModelRef.current && !commentModelRef.current.contains(event.target)) {
        setCommentNodeData((prevCommentNodeData) => {
          if (prevCommentNodeData && prevCommentNodeData.node_id) {
            emitUpdateActionNodes(parseInt(prevCommentNodeData.node_id, 10), 'remove');
          }
          return prevCommentNodeData;
        });

        setShowCommentModel(false);
        handleDaigramHighlight(null, false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideCommentModal);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCommentModal);
    };
  }, [commentModelRef, workSpaceAccess]);

  const handleDaigramHighlight = (node, highLightFlag = null) => {
    if (diagramRef.current) {
      const diagram2 = diagramRef.current.getDiagram();
      diagram2.nodes.each((n) => {
        if (highLightFlag !== null) {
          n.isHighlighted = highLightFlag;
        } else if (showCommentModel) {
          n.isHighlighted = false;
        } else {
          n.isHighlighted = true;
        }
      });
    }
    if (node) {
      if (diagramRef.current) {
        const diagram2 = diagramRef.current.getDiagram();
        // diagram2.select(node.part);
        diagram2.commandHandler.scrollToPart(node.part);
        // diagram2.centerRect(node.part.actualBounds, 50);
      }
      node.part.isHighlighted = false;
    }
  };

  const handleCommentModel = (node) => {
    handleDaigramHighlight(node);
    if (node) {
      node.part.isHighlighted = false;
      const nodeId = node.part.data.key;
      setCommentNodeData({
        work_space_id: workspaceId,
        node_id: nodeId.toString(),
      });

      emitUpdateActionNodes(nodeId, 'add');
    } else {
      if (commentNodeData && commentNodeData.node_id)
        emitUpdateActionNodes(parseInt(commentNodeData.node_id, 10), 'remove');
      setCommentNodeData(null);
    }

    setShowCommentModel(!showCommentModel);
  };

  useEffect(() => {
    if (diagramRef.current) return;
    const diagram = diagramRef.current.getDiagram(); // refs are up-to-date
    if (diagram instanceof go.Diagram) {
      setObserved(diagram);
    }

    /**
     * Get the diagram reference and remove listeners that were added during mounting.
     */
    return () => {
      if (diagramRef.current) return;
      const currentDiagram = diagramRef.current.getDiagram();
      if (currentDiagram instanceof go.Diagram) {
        setObserved(null);
      }
    };
  }, [diagramRef]);

  const handleNodeMouseDrop = (node) => {
    if (diagramRef.current && diagramRef.current !== 'TestGraph') {
      const diagram = diagramRef.current.getDiagram();
      const nodesData = diagram.model.nodeDataArray.map((nodeData) => nodeData.key);

      emitUpdateActionNodes(nodesData, 'remove');

      isNodeDragging.current = false;
    }
  };

  const handleRedirectToLogin = () => {
    localStorage.setItem(FREE_WORKSPACE_URL_LOCAL_STORAGE_KEY, pathname);
    navigate(routes.login);
  };

  // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
  diagram = $(go.Diagram, {
    'commandHandler.zoomFactor': 1.1,
    initialDocumentSpot: go.Spot.Top,
    padding: new go.Margin(100, 10, 10, 10),
    initialViewportSpot: go.Spot.Top,
    initialContentAlignment: go.Spot.Top,
    allowCopy: false,
    'draggingTool.dragsTree': true,
    'draggingTool.dragsLink': true,
    allowDelete: true,
    maxSelectionCount: 1, // users can select only one part at a time
    validCycle: go.Diagram.CycleDestinationTree, // make sure users can only create trees
    layout: $(go.TreeLayout, {
      treeStyle: go.TreeLayout.StyleLastParents,
      arrangement: go.TreeLayout.ArrangementHorizontal,
      angle: 90,
      layerSpacing: 35,
      alternateAngle: 90,
      alternateLayerSpacing: 35,
      alternateNodeSpacing: 20,
      alternatePortSpot: new go.Spot(0.5, 1, 0, 0),
      alternateChildPortSpot: go.Spot.Top,
      nodeSpacing: 100,
    }),
    'undoManager.isEnabled': true, // enable undo & redo
    mouseDrop: handleNodeMouseDrop,
    'grid.visible': true,
    ViewportBoundsChanged: updateGrid,
  });

  function updateGrid() {
    var diagram = diagramRef.current.getDiagram();
    const { scale } = diagram;
    const baseSize = 25; // Base size of grid cells

    // Adjust grid cell size based on the current zoom level
    const newSize = baseSize / scale;
    diagram.grid.gridCellSize = new go.Size(newSize, newSize);

    diagram.grid.findObject('lineH1').strokeWidth = 0.5 / scale;
    diagram.grid.findObject('lineH2').strokeWidth = 0.5 / scale;
    diagram.grid.findObject('lineV1').strokeWidth = 0.5 / scale;
    diagram.grid.findObject('lineV2').strokeWidth = 0.5 / scale;
  }

  const initDiagram = () => {
    go.Shape.defineFigureGenerator('RoundedTopRectangle', (shape, w, h) => {
      var geo = new go.Geometry();
      var fig = new go.PathFigure(0, 11.5, true);
      geo.add(fig);
      fig.add(new go.PathSegment(go.PathSegment.Arc, 180, 90, 11.5, 11.5, 11.5, 11.5));
      fig.add(new go.PathSegment(go.PathSegment.Line, w - 11.5, 0));
      fig.add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - 11.5, 11.5, 11.5, 11.5));
      fig.add(new go.PathSegment(go.PathSegment.Line, w, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, h));
      fig.add(new go.PathSegment(go.PathSegment.Line, 0, 11.5).close());
      return geo;
    });

    diagram.scrollMode = go.Diagram.InfiniteScroll;

    diagram.grid = $(
      go.Panel,
      'Grid',
      { gridCellSize: new go.Size(25, 25) },
      $(go.Shape, 'LineH', { stroke: 'rgba(240, 84, 54, 0.02)', strokeWidth: 0.5, interval: 1, name: 'lineH1' }),
      $(go.Shape, 'LineH', { stroke: 'rgba(240, 84, 54, 0.20)', strokeWidth: 0.5, interval: 4, name: 'lineH2' }),
      $(go.Shape, 'LineV', { stroke: 'rgba(240, 84, 54, 0.02)', strokeWidth: 0.5, interval: 1, name: 'lineV1' }),
      $(go.Shape, 'LineV', { stroke: 'rgba(240, 84, 54, 0.20)', strokeWidth: 0.5, interval: 4, name: 'lineV2' }),
    );

    // the template for each attribute in a node's array of item data (Examples)
    var itemTempl = $(
      go.Panel,
      'TableRow',
      $(go.Shape, 'Circle', {
        column: 1,
        desiredSize: new go.Size(9, 9),
        strokeJoin: 'round',
        strokeWidth: 2.5,
        stroke: '#F05436',
        fill: '#F05436',
        name: 'point',
        margin: new go.Margin(0, 10, 10, 0),
      }),
      $(
        go.TextBlock,
        textStyle(),
        {
          spacingAbove: 1.5,
          column: 2,
          minSize: new go.Size(295, NaN),
          maxSize: new go.Size(295, NaN),
          margin: new go.Margin(0, 10, 10, 0),
          stroke: '#fff',
          name: 'item',
          font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
          editable: false, // by default newlines are allowed
        },
        new go.Binding('text', '').makeTwoWay(),
      ),
    );

    diagram.nodeTemplate = $(
      go.Node,
      'Spot',
      new go.Binding('visible'),
      new go.Binding('isCollapse', 'isCollapse', true),
      new go.Binding('position', 'pos', go.Point.parse).makeTwoWay(go.Point.stringify),
      {
        selectionObjectName: 'BODY',
        isShadowed: true,
        shadowOffset: new go.Point(0, 0),
        shadowColor: 'white',
        shadowBlur: 13,
        click: (e, obj) => {
          if (!(obj.part instanceof go.Link) && obj.part.data.icon === 'idea.svg') selectedForExport(obj.part.data.key);
        },
        // dragsTree: true,
        // mouseEnter: (e, node) => node.findObject("BUTTON").opacity = node.findObject("BUTTONX").opacity = 1,
        // mouseLeave: (e, node) => node.findObject("BUTTON").opacity = node.findObject("BUTTONX").opacity = 0,
        // handle dragging a Node onto a Node to (maybe) change the reporting relationship
        // draggable: false,
        mouseDragEnter: (e, node, prev) => {
          const { diagram } = node;
          const selnode = diagram.selection.first();
          if (!mayWorkFor(selnode, node)) return;
          const shape = node.findObject('SHAPE');
          if (shape) {
            shape._prevFill = shape.fill; // remember the original brush
            shape.fill = 'darkred';
          }
        },
        mouseDragLeave: (e, node, next) => {
          const shape = node.findObject('SHAPE');
          if (shape && shape._prevFill) {
            shape.fill = shape._prevFill; // restore the original brush
          }
        },
        mouseDrop: (e, node) => {
          const { diagram } = node;
          const selectedNode = diagram.selection.first();

          if (mayWorkFor(selectedNode, node)) {
            const secondNodeData = {
              ...selectedNode.data,
              ...{ parents: getAllParentPromptJson(selectedNode) },
            };

            node.data.second = secondNodeData;

            diagram.model.setDataProperty(node.data, 'isMerged', true);
            if (secondNodeData.main) diagram.model.setDataProperty(node.data, 'secondMain', secondNodeData.main);
            if (secondNodeData.body) diagram.model.setDataProperty(node.data, 'secondBody', secondNodeData.body);
            if (secondNodeData.information)
              diagram.model.setDataProperty(node.data, 'secondInformation', secondNodeData.information);

            diagram.remove(selectedNode);

            emitCreateUpdateNodes({ nodeId: node.data.key, data: secondNodeData }, 'mergeNode');
            save(true);

            const nodesData = diagram.model.nodeDataArray.map((nodeData) => nodeData.key);
            emitUpdateActionNodes(nodesData, 'remove');
          }
        },
        dragComputation: (part, pt, gridPt) => {
          if (isNodeDragging.current !== true) {
            isNodeDragging.current = true;

            const nodesData = diagram.model.nodeDataArray.map((nodeData) => nodeData.key);
            emitUpdateActionNodes(nodesData, 'add');
          }
          return pt;
        },
        mouseLeave: (e, node) => {},
      },
      {
        selectionAdornmentTemplate: $(
          go.Adornment,
          'Auto',
          $(go.Shape, 'RoundedRectangle', {
            fill: null,
            strokeWidth: 0,
            parameter1: 15,
            stroke: '#F05436',
          }),
          $(go.Placeholder),
        ), // end Adornment
      },
      // for sorting, have the Node.text be the data.name
      new go.Binding('text', 'name'),
      // bind the Part.layerName to control the Node's layer depending on whether it isSelected
      new go.Binding('layerName', 'isSelected', (sel) => (sel ? 'Foreground' : '')).ofObject(),
      $(
        go.Panel,
        'Auto',
        {
          shadowVisible: true,
          name: 'BODY',
          margin: new go.Margin(40, 5, 0, 5),
        },
        // define the node's outer shape
        $(
          go.Shape,
          'RoundedRectangle',
          {
            name: 'SHAPE',
            parameter1: 15,
            fill: '#09091D',
            // stroke: 'white',
            strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            portId: '',
          },
          new go.Binding('opacity', '', (data, obj) => {
            var moreActionsMenu = obj.part.findObject('moreActionsMenu');
            return (moreActionsMenu && moreActionsMenu.height > 0) || obj.part.isHighlighted ? 0.1 : 1;
          }).ofObject('moreActionsMenu'),
          new go.Binding('opacity', '', (data, obj) => {
            var moreActionsMenu = obj.part.findObject('moreActionsMenu');
            return (moreActionsMenu && moreActionsMenu.height > 0) || obj.part.isHighlighted ? 0.1 : 1;
          }).ofObject(),
        ),
        $(
          go.Panel,
          'Vertical',
          new go.Binding('opacity', '', (data, obj) => {
            var moreActionsMenu = obj.part.findObject('moreActionsMenu');
            return (moreActionsMenu && moreActionsMenu.height > 0) || obj.part.isHighlighted ? 0.1 : 1;
          }).ofObject('moreActionsMenu'),
          new go.Binding('opacity', '', (data, obj) => {
            var moreActionsMenu = obj.part.findObject('moreActionsMenu');
            return (moreActionsMenu && moreActionsMenu.height > 0) || obj.part.isHighlighted ? 0.1 : 1;
          }).ofObject(),
          $(
            go.Panel,
            'Table',
            new go.Binding('minSize', 'isMerged', (val, obj) => (val ? new go.Size(720, NaN) : new go.Size(325, NaN))),
            new go.Binding('maxSize', 'isMerged', (val, obj) => (val ? new go.Size(720, NaN) : new go.Size(325, NaN))),
            {
              // minSize: new go.Size(325, NaN),
              // maxSize: new go.Size(325, NaN),
              margin: new go.Margin(45, 13, 0, 13),
              defaultAlignment: go.Spot.Left,
            },
            $(go.RowColumnDefinition, { column: 2, width: 4 }),

            $(
              go.Panel,
              'Horizontal',
              {
                // minSize: new go.Size(NaN, 152),
                // maxSize: new go.Size(NaN, 152),
                name: 'textPanelMain',
              },
              $(
                go.Panel,
                'Vertical',
                {
                  minSize: new go.Size(NaN, 162),
                  // maxSize: new go.Size(NaN, 162),
                  name: 'textPanel',
                },
                $(
                  go.TextBlock,
                  {
                    click: openTextBlockEditor,
                    textEdited: handleTextBlockTextEdited,
                  },
                  textStyle(), // the main title
                  {
                    name: 'NAMETB',
                    row: 0,
                    column: 0,
                    columnSpan: 2,
                    font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                    wrap: go.TextBlock.WrapFit,
                    editable: false,
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, NaN),
                    textAlign: 'center',
                    stroke: '#F05436',
                    overflow: go.TextBlock.OverflowEllipsis,
                  },
                  new go.Binding('text', 'main', function (main) {
                    return main.toUpperCase();
                  }).makeTwoWay(),
                ),

                $(
                  go.TextBlock,
                  {
                    click: openTextBlockEditor,
                    textEdited: handleTextBlockTextEdited,
                  },
                  textStyle(), // the body
                  {
                    spacingBelow: 1.5,
                    visible: true,
                    name: 'body',
                    row: 2,
                    column: 0,
                    columnSpan: 2,
                    font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                    editable: false, // by default newlines are allowed
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, 92),
                    margin: new go.Margin(5, 0, 0, 0),
                    stretch: go.GraphObject.Fill,
                    overflow: go.TextBlock.OverflowEllipsis,
                  },
                  new go.Binding('text', 'body', function (body) {
                    return body;
                  }).makeTwoWay(),
                ),

                $(
                  go.TextBlock,
                  textStyle(), // the information
                  {
                    spacingBelow: 1.5,
                    visible: true,
                    name: 'information',
                    row: 2,
                    column: 0,
                    columnSpan: 2,
                    font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                    editable: false, // by default newlines are allowed
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, 92),
                    margin: new go.Margin(12, 0, 0, 0),
                    stretch: go.GraphObject.Fill,
                    overflow: go.TextBlock.OverflowEllipsis,
                  },
                  new go.Binding('text', 'information').makeTwoWay(),
                ),

                $(
                  go.TextBlock,
                  textStyle(), // Show more/Show less button
                  {
                    name: 'textCollapse',
                    row: 0,
                    column: 0,
                    columnSpan: 2,
                    font: '9pt poppins, Helvetica, Arial, sans-serif',
                    wrap: go.TextBlock.WrapFit,
                    editable: false,
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, NaN),
                    stroke: '#F05436',
                    margin: new go.Margin(0, 0, 0, 0),
                    isUnderline: true,
                    cursor: 'pointer',
                    click: (e, button) => adjustTextBlockHeight(button.part),
                    text: 'Show more',
                  },
                  new go.Binding('visible', (node) => {
                    const textData = node?.information || node?.body || '';
                    const result = checkBodyInformationOverflowing(textData);
                    return result;
                  }),
                ),
              ),
              $(
                go.Panel,
                'Vertical',
                {
                  height: new go.Size(NaN, 152),
                  row: 4,
                  visible: false,
                  minSize: new go.Size(NaN, 152),
                  maxSize: new go.Size(NaN, 152),
                  name: 'textPanel',
                },
                new go.Binding('visible', 'isMerged', (val, obj) => val || false),
                $(go.Picture, {
                  name: 'combinedNode',
                  // click: (e, button) => addCustomNode(button.part),
                  cursor: 'pointer',
                  desiredSize: new go.Size(15, 15),
                  margin: new go.Margin(100, 30, 0, 30),
                  source: '/assets/images/add-orange-new.png',
                }),
              ),
              $(
                go.Panel,
                'Vertical',
                {
                  // visible: (val, obj) => {
                  //   console.log('in vertical', val, obj);
                  //   return false;
                  // },
                  visible: false,
                  minSize: new go.Size(NaN, 152),
                  maxSize: new go.Size(NaN, 152),
                  name: 'secondNode',
                },
                new go.Binding('visible', 'isMerged', (val, obj) => val || false),
                $(
                  go.TextBlock,
                  textStyle(), // the main title
                  {
                    name: 'NAMETB',
                    row: 0,
                    column: 0,
                    columnSpan: 2,
                    font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                    wrap: go.TextBlock.WrapFit,
                    editable: false,
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, NaN),
                    textAlign: 'center',
                    stroke: '#F05436',
                    overflow: go.TextBlock.OverflowEllipsis,
                  },
                  new go.Binding('text', '', function (data) {
                    return (data.second.main || data.secondMain).toUpperCase();
                  }).makeTwoWay(),
                ),

                $(
                  go.TextBlock,
                  textStyle(), // the body
                  {
                    spacingBelow: 1.5,
                    visible: true,
                    name: 'body',
                    row: 2,
                    column: 0,
                    columnSpan: 2,
                    font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                    editable: false, // by default newlines are allowed
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, 105),
                    margin: new go.Margin(5, 0, 0, 0),
                    stretch: go.GraphObject.Fill,
                    overflow: go.TextBlock.OverflowEllipsis,
                  },
                  new go.Binding('text', '', function (data) {
                    return data.second.body || data.secondBody;
                  }).makeTwoWay(),
                ),

                $(
                  go.TextBlock,
                  textStyle(), // the information
                  {
                    spacingBelow: 1.5,
                    visible: true,
                    name: 'information',
                    row: 2,
                    column: 0,
                    columnSpan: 2,
                    font: '9pt poppinsregular, Helvetica, Arial, sans-serif',
                    editable: false, // by default newlines are allowed
                    minSize: new go.Size(325, NaN),
                    maxSize: new go.Size(325, 92),
                    margin: new go.Margin(12, 0, 0, 0),
                    stretch: go.GraphObject.Fill,
                    overflow: go.TextBlock.OverflowEllipsis,
                  },
                  new go.Binding('text', '', function (data) {
                    return data.second.information || data.secondInformation;
                  }).makeTwoWay(),
                ),
              ),
            ),

            $(
              go.Panel,
              'Table',
              {
                visible: false,
                name: 'examples',
                row: 2,
                margin: new go.Margin(0, 0, 30, 0),
                itemTemplate: itemTempl,
              },
              new go.Binding('itemArray', 'examples'),
            ),

            $(
              go.Panel,
              'Horizontal',
              {
                visible: false,
                row: 3,
                name: 'add',
                cursor: 'pointer',
                margin: new go.Margin(15, 0, 0, 0),
                // alignment: go.Spot.Center,
                // click: (e, button) => addItem(button.part)
              },
              $(go.Shape, 'PlusLine', {
                width: 7,
                height: 7,
                stroke: '#fff',
                margin: new go.Margin(0, 3, 1.9, 0),
                strokeWidth: 1.5,
              }),
              $(go.TextBlock, 'Add Another Example', {
                font: '8pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#fff',
              }),
            ),

            $(
              go.Panel,
              'Horizontal',
              {
                visible: false,
                row: 3,
                name: 'blank',
                margin: new go.Margin(15, 0, 0, 0),
                height: 45,
              },
              new go.Binding('visible', 'main', (val, obj) => val === 'MORE INFORMATION'),
            ),

            $(
              go.Panel,
              'Horizontal',
              {
                height: 45,
                alignment: go.Spot.Right,
                stretch: go.GraphObject.Fill,
                row: 4,
                columnSpan: 2,
                margin: new go.Margin(16, 0, 0, 0),
              },
              new go.Binding('visible', 'main', (val, obj) => {
                return (
                  val !== 'ACTION STEPS' &&
                  val !== 'EXAMPLES' &&
                  val !== 'MORE INFORMATION' &&
                  workSpaceAccess?.data?.role === 'editor'
                );
              }),
              $(
                go.Panel,
                'Horizontal',
                {
                  margin: new go.Margin(0, 0, 0, 0),
                  name: 'BtnPanel',
                },

                $(
                  go.Panel,
                  'Spot', // generate idea
                  {
                    click: (e, button) => loadPythonScript('my_prompt_value', 'IDEA', button.part),
                    cursor: 'pointer',
                    name: 'ideaBtn',
                  },
                  $(go.Shape, 'RoundedRectangle', {
                    parameter1: 11,
                    width: 35,
                    height: 35,
                    strokeWidth: 0,
                    margin: new go.Margin(0, 5, 5, 0),
                    fill: '#F05436',
                    name: 'ideaButton',
                  }),
                  $(go.Picture, {
                    name: 'ideaButtonPicture',
                    desiredSize: new go.Size(22, 22),
                    margin: 1.5,
                    source: '/assets/images/idea-white.svg',
                  }),
                  {
                    toolTip: $(
                      'ToolTip',
                      {
                        margin: 0,
                        background: '#09091D',
                        width: 'auto',
                      },
                      $(
                        go.Panel,
                        $(
                          go.TextBlock,
                          {
                            row: 0,
                            column: 0,
                            columnSpan: 2,
                            font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                            wrap: go.TextBlock.WrapFit,
                            textAlign: 'center',
                            stroke: '#F05436',
                            overflow: go.TextBlock.OverflowEllipsis,
                          },
                          'Generate Idea',
                        ),
                      ),
                    ),
                  },
                ),

                $(
                  go.Panel,
                  'Spot', // generate example
                  {
                    click: (e, button) => loadPythonScript('my_prompt_value', 'EXAMPLE', button.part),
                    cursor: 'pointer',
                    name: 'exampleBtn',
                  },
                  $(
                    go.Shape,
                    'RoundedRectangle',
                    {
                      parameter1: 11,
                      width: 35,
                      height: 35,
                      strokeWidth: 0,
                      margin: new go.Margin(0, 5, 5, 0),
                      fill: '#F05436',
                      name: 'exampleButton',
                    },
                    new go.Binding('visible', 'isMerged', (val, obj) => !val || false),
                    new go.Binding('width', 'isMerged', (val, obj) => (val ? 0 : 35)),
                  ),
                  $(
                    go.Picture,
                    {
                      name: 'exampleButtonPicture',
                      desiredSize: new go.Size(22, 22),
                      margin: 1.5,
                      // cursor: "pointer",
                      source: '/assets/images/list-white.svg', // the default image
                      // click: (e, button) => addInfo(button.part)
                    },
                    new go.Binding('visible', 'isMerged', (val, obj) => !val || false),
                  ),
                  {
                    toolTip: $(
                      'ToolTip',
                      {
                        margin: 0,
                        background: '#09091D',
                        width: 'auto',
                      },
                      $(
                        go.Panel,
                        $(
                          go.TextBlock,
                          {
                            row: 0,
                            column: 0,
                            columnSpan: 2,
                            font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                            wrap: go.TextBlock.WrapFit,
                            textAlign: 'center',
                            stroke: '#F05436',
                            overflow: go.TextBlock.OverflowEllipsis,
                          },
                          'Generate Action Plan',
                        ),
                      ),
                    ),
                  },
                  new go.Binding('visible', 'isMerged', (val, obj) => !val || false),
                ),
              ),
            ), // Idea & Steps Buttons

            $(
              go.Panel,
              'Spot',
              {
                click: (e, button) => refreshExample(button.part),
                cursor: 'pointer',
                alignment: go.Spot.Right,
                row: 4,
                column: 0,
                margin: new go.Margin(0, 55, 12, 0), // Added space at the bottom
                visible: false,
                name: 'refreshExampleButton',
              },
              $(go.Picture, {
                name: 'Refresh_Img',
                desiredSize: new go.Size(25, 25),
                source: '/assets/images/refresh.png',
              }),
              new go.Binding(
                'visible',
                'main',
                (val, obj) =>
                  (val === 'ACTION STEPS' || val === 'EXAMPLES') && workSpaceAccess?.data?.role === 'editor',
              ),
              {
                toolTip: $(
                  'ToolTip',
                  {
                    margin: 0,
                    background: '#09091D',
                    width: 'auto',
                  },
                  $(
                    go.Panel,
                    $(
                      go.TextBlock,
                      {
                        row: 0,
                        column: 0,
                        columnSpan: 2,
                        font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                        wrap: go.TextBlock.WrapFit,
                        textAlign: 'center',
                        stroke: '#F05436',
                        overflow: go.TextBlock.OverflowEllipsis,
                      },
                      'Refresh Steps',
                    ),
                  ),
                ),
              },
            ), // Refresh Steps Button
          ), // end Table Panel
        ), // end vertical Panel
        new go.Binding('opacity', 'isCommented', (sel) => (sel ? 1.0 : 0.0)).ofObject(),
      ), // end Auto Panel

      $(
        go.Panel,
        'Spot',
        {
          visible: false,
          alignment: go.Spot.Center,
          stretch: go.GraphObject.Fill,
          name: 'loader',
        },
        $(
          go.Shape,
          'RoundedRectangle',
          {
            fill: '#41464b5e',
            strokeWidth: 0,
            parameter1: 15,
          },
          new go.AnimationTrigger('angle'),
        ),

        $(
          go.Picture,
          {
            name: 'spinner',
            desiredSize: new go.Size(60, 60),
            // angle: 45,
            source: '/assets/images/spinner.svg', // the default image
            // source: SpinnerSvg, // the default image
          },
          new go.AnimationTrigger('angle'),
        ),
      ),

      $(
        go.Panel,
        'Spot',
        {
          alignment: go.Spot.Top,
        },
        $(go.Shape, 'Circle', {
          width: 50,
          // strokeWidth: 1.5,
          // stroke: '#fff',
          shadowVisible: true,
          strokeWidth: 0,
          fill: '#09091D',
        }),
        $(
          go.Picture,
          {
            name: 'Picture',
            desiredSize: new go.Size(26, 26),
            margin: 1,
            source: '/assets/images/goal.svg', // the default image
          },
          new go.Binding('source', 'icon', findHeadShot),
        ),
      ),

      $(
        go.Panel,
        'Position',
        {
          alignment: new go.Spot(1, 1, -25, -10),
          alignmentFocus: go.Spot.BottomRight,
        },
        new go.Binding('visible', '', (val, obj) => {
          return workSpaceAccess?.data?.role === 'editor';
        }),
        new go.Binding('opacity', '', (data, obj) => {
          var loader = obj.part.findObject('loader');
          if (loader && loader.visible) return 0.5;

          if (obj.part.isHighlighted) return 0.1;

          return 1;
        }).ofObject('loader'),
        new go.Binding('opacity', '', (data, obj) => {
          var loader = obj.part.findObject('loader');
          if (loader && loader.visible) return 0.5;

          if (obj.part.isHighlighted) return 0.1;

          return 1;
        }).ofObject(),
        $(
          go.Panel,
          'Spot',
          {
            click: (e, object) => {
              const loader = object.part.findObject('loader');
              if ((loader && loader.visible) || object.part.isHighlighted) {
                e.handled = true;
                return;
              }
              handleShowActionsMenu(object);
            },
            alignment: go.Spot.Right,
            cursor: 'pointer',
            name: 'moreActionsMenuButton',
            margin: new go.Margin(0, 0, 5, 0),
          },
          new go.Binding('cursor', '', (data, obj) => {
            var loader = obj.part.findObject('loader');
            return (loader && loader.visible) || obj.part.isHighlighted ? 'default' : 'pointer';
          }).ofObject('loader'),
          new go.Binding('cursor', '', (data, obj) => {
            var loader = obj.part.findObject('loader');
            return (loader && loader.visible) || obj.part.isHighlighted ? 'default' : 'pointer';
          }).ofObject(),
          $(go.Shape, 'RoundedRectangle', {
            width: 35,
            height: 35,
            strokeWidth: 1,
            stroke: '#F05436',
            fill: '#09091d',
          }),
          $(go.Picture, {
            name: 'moreActionsMenuButtonPicture',
            desiredSize: new go.Size(22, 22),
            margin: 1.5,
            source: '/assets/images/add-new.png',
            angle: 0,
          }),
          new go.Binding('toolTip', '', (data, obj) => {
            var loader = obj.part.findObject('loader');
            return (loader && loader.visible) || obj.part.isHighlighted
              ? null
              : $(
                  'ToolTip',
                  {
                    margin: 0,
                    background: '#09091D',
                    width: 'auto',
                  },
                  $(
                    go.Panel,
                    $(
                      go.TextBlock,
                      {
                        row: 0,
                        column: 0,
                        columnSpan: 2,
                        font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                        wrap: go.TextBlock.WrapFit,
                        textAlign: 'center',
                        stroke: '#F05436',
                        overflow: go.TextBlock.OverflowEllipsis,
                      },
                      'More Actions',
                    ),
                  ),
                );
          }).ofObject('loader'),
          new go.Binding('toolTip', '', (data, obj) => {
            var loader = obj.part.findObject('loader');
            return (loader && loader.visible) || obj.part.isHighlighted
              ? null
              : $(
                  'ToolTip',
                  {
                    margin: 0,
                    background: '#09091D',
                    width: 'auto',
                  },
                  $(
                    go.Panel,
                    $(
                      go.TextBlock,
                      {
                        row: 0,
                        column: 0,
                        columnSpan: 2,
                        font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
                        wrap: go.TextBlock.WrapFit,
                        textAlign: 'center',
                        stroke: '#F05436',
                        overflow: go.TextBlock.OverflowEllipsis,
                      },
                      'More Actions',
                    ),
                  ),
                );
          }).ofObject(),
        ),
      ), // Action Menu Button

      $(
        go.Panel,
        'Position',
        {
          name: 'moreActionsMenu',
          height: 0,
          alignment: new go.Spot(1, 1, -25, -65),
          alignmentFocus: go.Spot.BottomRight,
        },
        new go.Binding('visible', '', (data, obj) => {
          var loader = obj.part.findObject('loader');
          return !((loader && loader.visible) || obj.part.isHighlighted);
        }).ofObject('loader'),
        new go.Binding('visible', '', (data, obj) => {
          var loader = obj.part.findObject('loader');
          return !((loader && loader.visible) || obj.part.isHighlighted);
        }).ofObject(),
        $(go.Shape, 'RoundedRectangle', {
          fill: '#09091d',
          strokeWidth: 0,
          parameter1: 10,
          height: 200,
          width: 160,
          shadowVisible: true,
        }),
        $(
          go.Panel,
          'Table',
          {
            height: 200,
            width: 160,
          },

          $(
            go.Panel,
            'Auto',
            {
              row: 0,
              width: 160,
              column: 0,
              columnSpan: 3,
              padding: new go.Margin(0, 0, 0, 0),
              margin: new go.Margin(-5.2, 0, 0, 0),
            },
            $(go.Shape, 'RoundedTopRectangle', {
              fill: '#F05436',
              strokeWidth: 0,
              parameter1: 10,
              spot1: new go.Spot(0, 0, 0, 0),
              spot2: new go.Spot(1, 1, 0, 0),
            }),
            $(go.TextBlock, 'More Actions', {
              font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
              textAlign: 'left',
              stroke: '#fff',
              margin: new go.Margin(10, 15, 7, -47),
            }),
          ), // More Actions Label

          $(
            go.Panel,
            'Auto',
            {
              width: 160,
              row: 1,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              padding: new go.Margin(0, 0, 0, 10),
            },
            $(go.TextBlock, 'Generate', {
              font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
              stroke: '#c2c2c2',
            }),
          ), // Generate Label

          $(
            go.Panel,
            'Auto',
            {
              mouseEnter: (e, obj) => {
                obj.background = '#F05436';
              },
              mouseLeave: (e, obj) => {
                obj.background = null;
              },
              width: 160,
              row: 2,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              padding: new go.Margin(3, 1, 2.5, 10),
              cursor: 'pointer',
              click: (e, object) => loadPythonScript('my_prompt_value', 'INFO', object.part),
            },

            $(
              go.Panel,
              'Auto',
              { alignment: go.Spot.Left },
              $(go.Picture, {
                name: 'ideaButtonPicture',
                desiredSize: new go.Size(12, 12),
                margin: new go.Margin(0.5, 0, 0, 0),
                source: '/assets/images/question-mark.svg',
              }),
            ),

            $(
              go.Panel,
              'Auto',
              {
                alignment: go.Spot.Left,
                padding: new go.Margin(1, 0, 0, 17),
              },
              $(go.TextBlock, 'More Info', {
                font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#fff',
              }),
            ),
          ), // More Info Button

          $(
            go.Panel,
            'Auto',
            {
              width: 160,
              row: 3,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              margin: new go.Margin(0, 0, 0, -0.1),
              padding: new go.Margin(0, 0, 0, 0),
              height: 5,
            },

            $(go.Shape, 'LineH', {
              stroke: '#c2c2c2',
              strokeWidth: 0.5,
              alignment: go.Spot.Bottom,
              margin: new go.Margin(0, 0, 0, 0),
            }),
          ), // Devider

          $(
            go.Panel,
            'Auto',
            {
              width: 160,
              row: 4,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              padding: new go.Margin(0, 0, 0, 10),
            },
            $(go.TextBlock, 'Add', {
              font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
              stroke: '#c2c2c2',
            }),
          ), // Add Label

          $(
            go.Panel,
            'Auto',
            {
              mouseEnter: (e, obj) => {
                obj.background = '#F05436';
              },
              mouseLeave: (e, obj) => {
                obj.background = null;
              },
              width: 160,
              row: 5,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              padding: new go.Margin(3, 1, 2.5, 10),
              cursor: 'pointer',
              click: (e, object) => addCustomNode(object.part),
            },

            $(
              go.Panel,
              'Auto',
              { alignment: go.Spot.Left },
              $(go.Picture, {
                name: 'ideaButtonPicture',
                desiredSize: new go.Size(10, 10),
                margin: new go.Margin(0.5, 0, 0, 0),
                source: '/assets/images/add-orange.png',
              }),
            ),

            $(
              go.Panel,
              'Auto',
              {
                alignment: go.Spot.Left,
                padding: new go.Margin(1, 0, 0, 17),
              },
              $(go.TextBlock, 'Your Idea', {
                font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#fff',
              }),
            ),
          ), // Your Idea Button

          $(
            go.Panel,
            'Auto',
            {
              mouseEnter: (e, obj) => {
                obj.background = '#F05436';
              },
              mouseLeave: (e, obj) => {
                obj.background = null;
              },
              width: 160,
              row: 6,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              padding: new go.Margin(3, 0, 2.5, 8),
              cursor: 'pointer',
              click: (e, object) => handleOpenNodeCommentModel(object),
            },

            $(
              go.Panel,
              'Auto',
              { alignment: go.Spot.Left },
              $(go.Picture, {
                name: 'ideaButtonPicture',
                desiredSize: new go.Size(15, 15),
                margin: new go.Margin(0, 0, 0, 0),
                source: '/assets/images/comment.png',
              }),
            ),

            $(
              go.Panel,
              'Auto',
              {
                alignment: go.Spot.Left,
                padding: new go.Margin(1, 0, 0, 19),
              },
              $(go.TextBlock, 'Comments', {
                font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#fff',
              }),
            ),
          ), // Comments Button

          $(
            go.Panel,
            'Auto',
            {
              width: 160,
              row: 7,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              margin: new go.Margin(0, 0, 0, -0.1),
              padding: new go.Margin(0, 0, 0, 0),
              height: 5,
            },

            $(go.Shape, 'LineH', {
              stroke: '#c2c2c2',
              strokeWidth: 0.5,
              alignment: go.Spot.Bottom,
              margin: new go.Margin(0, 0, 0, 0),
            }),
          ), // Devider

          $(
            go.Panel,
            'Auto',
            {
              width: 160,
              row: 8,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              padding: new go.Margin(0, 0, 0, 10),
            },
            $(go.TextBlock, 'Edit', {
              font: 'normal 6pt poppins, Helvetica, Arial, sans-serif',
              stroke: '#c2c2c2',
            }),
          ), // Edit Label

          $(
            go.Panel,
            'Auto',
            {
              width: 160,
              row: 9,
              column: 0,
              columnSpan: 3,
              alignment: go.Spot.Left,
              padding: new go.Margin(1.5, 0, 2.5, 10),
              cursor: 'pointer',
              click: (e, object) => handleDeleteNode(object),
            },

            $(
              go.Panel,
              'Auto',
              { alignment: go.Spot.Left },
              $(go.Picture, {
                name: 'ideaButtonPicture',
                desiredSize: new go.Size(12, 12),
                source: '/assets/images/bin.svg',
              }),
            ),

            $(
              go.Panel,
              'Auto',
              {
                alignment: go.Spot.Left,
                padding: new go.Margin(1.5, 0, 0, 17),
              },
              $(go.TextBlock, 'Delete', {
                font: 'bold 9pt poppins, Helvetica, Arial, sans-serif',
                stroke: '#F05436',
              }),
            ),
          ), // Delete Button
        ),
      ), // Action Button Menu

      $(
        'Button',
        { visible: false },
        $(go.Shape, 'PlusLine', { width: 10, height: 10 }),
        {
          name: 'BUTTON',
          alignment: go.Spot.Right,
          opacity: 0, // initially not visible
          // click: (e, button) => addEmployee(button.part)
        },
        // button is visible either when node is selected or on mouse-over
        new go.Binding('opacity', 'isSelected', (s) => (s ? 1 : 0)).ofObject(),
      ),

      // Node Collapse Or Expand Button
      $(
        go.Panel,
        'Spot',
        {
          click: (e, button) => handleToggleNodes(button.part),
          cursor: 'pointer',
          alignment: go.Spot.Bottom,
          name: 'nodeToggleButtonWrapper',
          portId: 'nodeToggleButtonPort',
          fromSpot: go.Spot.Bottom,
        },
        $(
          go.Panel,
          'Auto',
          {
            // background: '#09091d',
            margin: new go.Margin(70, 0, 0, 0),
          },
          $(go.Shape, {
            figure: 'Rectangle',
            fill: null,
            strokeWidth: 0,
          }),
          $(
            go.Picture,
            {
              name: 'nodeToggleButton',
              // desiredSize: new go.Size(24, 24),
              margin: new go.Margin(4, 4, 8, 4),
            },
            // new go.Binding('opacity', (data) => {
            //   const nodeData = diagram.findNodeForKey(data.key);
            //   var children = nodeData.findLinksOutOf();
            //   return children.count > 0 ? 1 : 0;
            // }),
            new go.Binding('source', (data) => {
              const isCollapse = data.isCollapse ? data.isCollapse : false;
              return isCollapse ? '/assets/images/show.svg' : '/assets/images/hide.svg';
            }),
          ),
        ),
      ),

      new go.Binding('isTreeExpanded').makeTwoWay(),
    ); // end Node, a Spot Panel

    diagram.click = (e) => {
      e.diagram.commit((d) => {
        const diagram2 = diagramRef.current.getDiagram();
        diagram2.nodes.each((n) => {
          n.isExpportFlag = false;
        });
        d.clearHighlighteds();
      }, 'no highlighteds');
      setShowExportModel(false);
    };

    diagram.toolManager.hoverDelay = 100;
    diagram.animationManager.isEnabled = true;
    // diagram.animationManager.duration = 600;

    function checkBodyInformationOverflowing(text) {
      if (text) {
        const offscreenDiv = document.createElement('div');
        offscreenDiv.style.position = 'absolute';
        offscreenDiv.style.visibility = 'hidden';
        offscreenDiv.style.width = '260px';
        offscreenDiv.style.font = '9pt poppinsregular, Helvetica, Arial, sans-serif';
        offscreenDiv.style.lineHeight = '1.25';
        offscreenDiv.style.whiteSpace = 'normal';
        offscreenDiv.style.overflow = 'hidden';
        offscreenDiv.innerText = text;

        document.body.appendChild(offscreenDiv);
        const height = offscreenDiv.clientHeight;
        document.body.removeChild(offscreenDiv);

        return height > 92;
      }
      return false;
    }

    function refreshExample(node) {
      const parentNode = node.findTreeParentNode();
      loadPythonScript('my_prompt_value', 'EXAMPLE', parentNode, node);
      // console.log('clicked on', node.data, parentNode.data);
    }

    // define the Link template
    diagram.linkTemplate = $(
      go.Link,
      go.Link.Orthogonal,
      { layerName: 'Background', corner: 5 },
      $(
        go.Shape,
        new go.Binding('stroke', 'isHighlighted', (h) => {
          return h ? '#F05436' : '#F05436';
        }).ofObject(),
        {
          strokeWidth: 7,
          stroke: '#F05436',
        },
      ),
      {
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Bottom,
        fromPortId: 'nodeToggleButtonPort',
      },
    );

    function selectedForExport(s) {
      const diagram2 = diagramRef.current.getDiagram();
      const firstNode = diagram2.findNodeForKey(s.toString());
      if (firstNode.isHighlighted === true && firstNode.isExpportFlag === true) {
        const childExamples = firstNode.findTreeChildrenNodes();
        childExamples.each((n) => {
          if (n?.data?.main === 'ACTION STEPS' || n?.data?.main === 'EXAMPLES') {
            n.isHighlighted = false;
          }
        });
        firstNode.isHighlighted = false;
      } else if (firstNode.isHighlighted === false && firstNode.isExpportFlag === true) {
        const childExamples = firstNode.findTreeChildrenNodes();
        childExamples.each((n) => {
          if (n?.data?.main === 'ACTION STEPS' || n?.data?.main === 'EXAMPLES') {
            n.isHighlighted = true;
          }
        });
        firstNode.isHighlighted = true;
      }

      const highlightedNode = diagram2.nodes.filter(
        (n) => !!(!(n.part instanceof go.Link) && n.part.data.icon === 'idea.svg' && n.isHighlighted === true),
      );

      setIsAllSelected(!!(highlightedNode && Object.keys(highlightedNode).length === 0));
    }

    diagram.addDiagramListener('ObjectSingleClicked', (e) => {
      var { part, name } = e.subject;

      if (!['moreActionsMenuButton', 'moreActionsMenu'].includes(name) && part) {
        const menu = part.findObject('moreActionsMenu');
        if (menu && menu.height > 0) handleShowActionsMenu(e.subject);
      }
    });

    diagram.addDiagramListener('LayoutCompleted', (e) => {
      var { nodeDataArray } = diagram.model;
      for (var i = 0; i < nodeDataArray.length; i++) {
        var node = diagram.findNodeForData(nodeDataArray[i]);
        var children = node.findLinksOutOf();

        if (children.count <= 0) {
          const nodeToggleButtonWrapper = node.findObject('nodeToggleButtonWrapper');
          nodeToggleButtonWrapper.opacity = 0;
        }
      }
    });

    diagram.addDiagramListener('initialLayoutCompleted', (e) => {
      // diagram.scale = 1;
      // diagram.commandHandler.scrollToPart(diagram.findNodeForKey(1));

      const node = diagram.findNodeForKey(1);
      if (node) {
        const nodePosition = node.actualBounds.position;
        const viewportWidth = diagram.viewportBounds.width;
        const nodeWidth = node.actualBounds.width;
        const nodeHeight = node.actualBounds.height;

        // Center the node horizontally
        const x = nodePosition.x - viewportWidth / 2 + nodeWidth / 2;
        // Align the node to the top
        const y = nodePosition.y - nodeHeight / 2;

        diagram.position = new go.Point(x, y);
      }
    });

    // Text Edit event handler function
    function openTextBlockEditor(e, obj) {
      var tb = obj.part.findObject(obj.name);
      if (tb && tb.editable) {
        emitUpdateActionNodes(obj.part.data.key, 'add');

        e.diagram.commandHandler.editTextBlock(tb);
      }
    }

    function handleTextBlockTextEdited(obj, oldString, newString) {
      var tb = obj.part.findObject(obj.name);
      var node = obj.part;

      if (tb && tb.editable && node) {
        if (obj.name === 'body') {
          const textData = tb?.text || '';

          const result = checkBodyInformationOverflowing(textData);
          const textCollapse = node.findObject('textCollapse');
          textCollapse.visible = result;
        }

        emitCreateUpdateNodes(
          {
            nodeId: node.data.key,
            data: node.data,
          },
          'update',
        );

        emitUpdateActionNodes(node.data.key, 'remove');

        save(true);
      }
    }

    function startSpinnerAnimation(node) {
      var picture = node.findObject('spinner');
      if (picture) {
        var animation = new go.Animation();
        animation.add(picture, 'angle', picture.angle, 360);
        animation.runCount = Infinity;
        animation.start();
        // var angle = picture.rotate ? picture.rotate + 1 : 1;
        // picture.rotate = angle % 360;
      }
    }

    const loadPythonScript = async (prompt, category, node, exNode, isFirstGeneration = false) => {
      if (authToken && authToken !== '') {
        emitUpdateActionNodes(node.data.key, 'add');

        const children = node.findTreeChildrenNodes();
        const childrenTexts = [];
        let exExists = false;
        children.each((n) => {
          if (n?.data?.main === 'ACTION STEPS' || n?.data?.main === 'EXAMPLES') exExists = true;
        });
        if (exExists && category === 'EXAMPLE') {
          children.each((n) => {
            if (n?.data?.main === 'ACTION STEPS' || n?.data?.main === 'EXAMPLES') diagram.remove(n);
          });
        }
        children.each((n) => {
          if (n?.data?.main !== 'MORE INFORMATION' && n?.data?.main !== 'EXAMPLES' && n?.data?.main !== 'ACTION STEPS')
            childrenTexts.push(n?.data?.main);
          if (n?.data?.isMerged === true) childrenTexts.push(n?.data?.second?.main);
        });

        startSpinnerAnimation(node);

        const loader = node.findObject('loader');
        loader.visible = true;
        if (exNode) {
          const loader2 = exNode.findObject('loader');
          loader2.visible = true;
        }

        if (node.data.isMerged === true) {
          const idea1 = getAllParentPromptJson(node);
          const idea2 = node.data.second.parents;
          const asyncData = await getCombinedMindMapResultsObj.mutateAsync(
            {
              idea1,
              idea2,
              workspaceId: workSpaceAccess?.data?.work_space?.id || workspaceId,
            },
            {
              onSuccess: (data) => {},
              onError: async (data) => {
                const loader = node.findObject('loader');
                loader.visible = false;
                if (exNode) {
                  const loader2 = exNode.findObject('loader');
                  loader2.visible = false;
                }
                toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
              },
            },
          );
          if (asyncData) {
            addEmployee(node, JSON.stringify(asyncData[0]));
            loader.visible = false;
            if (exNode) {
              const loader2 = exNode.findObject('loader');
              loader2.visible = false;
            }
          }
          return;
        }

        const diagram2 = diagramRef.current.getDiagram();
        const jsonDecodedData = JSON.parse(diagram2.model.toJson());
        let counter = jsonDecodedData.nodeDataArray.length;

        if (counter === 1 && (category === 'INFO' || category === 'EXAMPLE')) {
          counter = 0;
          setScriptCounter(0);
        }

        const context = getAllParentPrompt(node);
        const ideas = getSameLevelNodesTitle(node);
        const prompt = context[0] || {};

        context.reverse();
        context.splice(-1);

        const asyncData = await getMindMapResultsObj.mutateAsync(
          {
            prompt,
            generation_type: category,
            context,
            workspace_id: workSpaceAccess?.data?.work_space?.id || workspaceId,
            ideas,
          },
          {
            onSuccess: (data) => {},
            onError: async (data) => {
              const loader = node.findObject('loader');
              loader.visible = false;
              toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
            },
          },
        );

        if (asyncData) {
          var result = asyncData[0];
          var arrays = asyncData;

          if (
            counter === 1 &&
            (document.getElementById('token').value === '' || document.getElementById('token').value === undefined) &&
            (document.getElementById('context0').value === '' ||
              document.getElementById('context0').value === undefined) &&
            category === 'IDEA'
          ) {
            var token = arrays[1];
            var context0 = JSON.stringify(arrays[2]);

            document.getElementById('token').value = token;
            document.getElementById('context0').value = context0 || '';
          }

          emitCreateUpdateNodes(
            {
              nodeId: node.data.key,
              category,
              data: JSON.stringify(result),
            },
            'create',
          );

          if (category === 'IDEA') {
            addEmployee(node, JSON.stringify(result));
          }
          if (category === 'INFO') {
            addInfo(node, JSON.stringify(result));
          }
          if (category === 'EXAMPLE') {
            addExample(node, JSON.stringify(result));
          }

          loader.visible = false;
          if (exNode) {
            const loader2 = exNode.findObject('loader');
            loader2.visible = false;
          }
        }

        if (isFirstGeneration) {
          const node = diagram.findNodeForKey(1);
          if (node) {
            const nodePosition = node.actualBounds.position;
            const viewportWidth = diagram.viewportBounds.width;
            const nodeWidth = node.actualBounds.width;
            const nodeHeight = node.actualBounds.height;

            // Center the node horizontally
            const x = nodePosition.x - viewportWidth / 2 + nodeWidth / 2;
            // Align the node to the top
            const y = nodePosition.y - nodeHeight / 2;

            diagram.position = new go.Point(x, y);
          }
        }

        emitUpdateActionNodes(node.data.key, 'remove');

        if (!node) return;
      } else {
        handleRedirectToLogin();
      }
    };

    const addCustomNode = async (node) => {
      if (authToken && authToken !== '') {
        if (!node) return;
        emitUpdateActionNodes(node.data.key, 'add');

        try {
          const thisemp = node.data;
          diagram.startTransaction('add employee');
          const newemp = {
            main: 'Your idea title here',
            body: 'Type description here...',
            icon: 'add-orange.svg',
            parent: thisemp.key,
            custom: true,
          };
          diagram.model.addNodeData(newemp);
          const newnode = diagram.findNodeForData(newemp);
          if (newnode) newnode.location = node.location;

          const body = newnode.findObject('body');
          body.visible = true;
          body.editable = true;
          body.isActionable = true;

          const title = newnode.findObject('NAMETB');
          title.editable = true;
          title.isActionable = true;

          diagram.commitTransaction('add employee');
          diagram.commandHandler.scrollToPart(newnode);

          await expandNode(node);

          emitCreateUpdateNodes(
            {
              nodeId: node.data.key,
              category: 'CUSTOM',
              data: newemp,
            },
            'create',
          );

          save(true);
        } catch (error) {
          toast.error(error.message, TOAST_CONFIG);
        }

        emitUpdateActionNodes(node.data.key, 'remove');
      } else {
        handleRedirectToLogin();
      }
    };

    function getAllParentPromptJson(node) {
      var parentChain = [];

      while (node !== null) {
        parentChain.unshift(`"${node?.data?.main || ''}"`);
        node = node.findTreeParentNode();
      }

      parentChain.reverse();
      return parentChain;
    }

    function getAllParentPrompt(node) {
      var parentChain = [];

      while (node !== null) {
        parentChain.unshift({
          main: node.data.main,
          body: node.data.information || node.data.body,
        });
        node = node.findTreeParentNode();
      }

      parentChain.reverse();
      return parentChain;
    }

    function getParentPrompt(node) {
      var parentNode = node.findTreeParentNode();
      const promptText = parentNode.findObject('NAMETB');

      if (
        promptText.text === 'MORE INFORMATION' ||
        promptText.text === 'EXAMPLES' ||
        promptText.text === 'ACTION STEPS'
      ) {
        getParentPrompt(parentNode);
      } else {
        $('#prompt').val(parentNode.data.main);
        $('#promptBody').val(parentNode.data.information || parentNode.data.body);
      }
    }

    function getSameLevelNodesTitle(node) {
      if (!node) return [];

      const sameLevelNodes = [];
      var nodeKey = node.data.key;
      var nodeParent = node.data.parent;

      diagram.nodes.each((node) => {
        if (node.data.key !== nodeKey && node.data.parent === nodeParent && node.data.icon === 'idea.svg')
          sameLevelNodes.push(node.data.main);
      });

      return sameLevelNodes;
    }

    async function addEmployee(node, output) {
      if (!node) return;

      try {
        var data = JSON.parse(output);
        // Iterate through the array using a for loop
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          const thisemp = node.data;
          diagram.startTransaction('add employee');
          const newemp = { main: item.main, information: item.body, icon: 'idea.svg', parent: thisemp.key };
          diagram.model.addNodeData(newemp);
          const newnode = diagram.findNodeForData(newemp);
          if (newnode) newnode.location = node.location;
          const body = newnode.findObject('body');
          body.visible = true;

          diagram.commitTransaction('add employee');
          diagram.commandHandler.scrollToPart(newnode);
        }

        await expandNode(node);

        save(true);
      } catch (error) {
        console.log('error', error);
        // Exception handling code
        // showAlertMessage("error", "Some error occured. Rerun the function again...")
      }
    }

    async function addExample(node, output) {
      if (!node) return;

      // console.log(output);

      try {
        if (!output.includes('{"main": "*", "body": ""}') && !output.includes('{"main": "*", "body": " "}')) {
          // alert("hello");
          // var substringToRemove = '{"main": "*", "body":"';
          // var substringToRemove1 = '{"main": "*", "body": "}';

          var modifiedStr = output.replace(/\{"main": \*, "body":/g, '');
          var modifiedStr1 = modifiedStr.replace(/{"main": "*", "body":"/g, '');
          var modifiedStr2 = modifiedStr1.replace(/"}"}/g, '"}');
          var modifiedStr3 = modifiedStr2.replace(/" "/g, '"');
          var modifiedStr4 = modifiedStr3.replace(/""/g, '"');
          var modifiedStr5 = modifiedStr4.replace(/\\'/g, "'");
        } else {
          // alert("hi");
          var modifiedStr5 = output;
        }
        // console.log(modifiedStr5);
        var data = JSON.parse(modifiedStr5);

        const examplesArray = new Array();
        var main = null;

        // Iterate through the array using a for loop
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          main = item.main;
          examplesArray[i] = item.body;
        }

        const thisemp = node.data;
        diagram.startTransaction('add employee');
        const newemp = { main: 'ACTION STEPS', icon: 'list.svg', parent: thisemp.key, examples: examplesArray };
        diagram.model.addNodeData(newemp);
        const newnode = diagram.findNodeForData(newemp);
        if (newnode) newnode.location = node.location;
        const examples = newnode.findObject('examples');
        examples.visible = true;

        const textBlock = newnode.findObject('textPanel');
        textBlock.maxSize = new go.Size(NaN, NaN);
        textBlock.minSize = new go.Size(NaN, NaN);
        // const add = newnode.findObject("add");
        // add.visible = "true";

        diagram.commitTransaction('add employee');
        diagram.commandHandler.scrollToPart(newnode);

        await expandNode(node);

        save(true);
        // console.log(examplesArray);
      } catch (error) {
        // Exception handling code
        // showAlertMessage("error", "Message was not in the right format. Rerun the function to get a different output")
      }
    }

    async function addInfo(node, output) {
      if (!node) return;

      try {
        var modifiedContent = output.replace(/(\b\w+)"|(")/g, function (match, word, quote) {
          if (quote) {
            return '\\"';
          }
          return `${word}\\"`;
        });
        var modifiedStr = output.replace(/"{"main":/g, '');
        var modifiedStr1 = modifiedStr.replace(/"body":/g, '');
        var modifiedStr2 = modifiedStr1.replace(/"}/g, '');
        var modifiedStr3 = modifiedStr2.replace(/\\"/g, '"');
        var modifiedStr4 = modifiedStr3.replace(/\\'/g, "'");

        var data = JSON.parse(modifiedStr4);

        var paragraphs = data.join('\n');

        // console.log("Output: " + paragraphs);

        const thisemp = node.data;
        diagram.startTransaction('add employee');
        const newemp = {
          main: 'MORE INFORMATION',
          information: paragraphs,
          icon: 'question.svg',
          parent: thisemp.key,
        };
        diagram.model.addNodeData(newemp);
        const newnode = diagram.findNodeForData(newemp);
        if (newnode) newnode.location = node.location;
        const information = newnode.findObject('body');
        information.visible = true;

        diagram.commitTransaction('add employee');
        diagram.commandHandler.scrollToPart(newnode);

        await expandNode(node);

        save(true);
      } catch (error) {
        // Exception handling code
        // showAlertMessage("error", "Message was not in the right format. Rerun the function to get a different output")
      }
    }

    function handleToggleNodes(node) {
      const isCollapse = node.data.isCollapse ? node.data.isCollapse : false;

      const nodeToggleButton = node.findObject('nodeToggleButton');
      if (isCollapse) {
        nodeToggleButton.source = '/assets/images/hide.svg';

        node.diagram.model.setDataProperty(node.data, 'isCollapse', false);

        // Hide Sub Nodes
        node.findTreeChildrenNodes().each((node) => {
          nodeCollapseOrExpand(node, true);
        });
      } else {
        nodeToggleButton.source = '/assets/images/show.svg';

        node.diagram.model.setDataProperty(node.data, 'isCollapse', true);

        // Show Sub Nodes
        node.findTreeChildrenNodes().each((node) => {
          nodeCollapseOrExpand(node, false);
        });
      }
    }

    function nodeCollapseOrExpand(node, visible) {
      diagram.startTransaction('animate visibility');

      var animation = new go.Animation();
      animation.isViewportUnchanged = true;
      animation.duration = 600;

      if (visible) animation.add(node, 'scale', 0.1, 1);
      else animation.add(node, 'scale', 1, 1);

      node.diagram.model.setDataProperty(node.data, 'visible', visible);

      function onAnimationFinished(e) {
        diagram.layout.invalidateLayout();
        diagram.layoutDiagram(true);

        diagram.removeDiagramListener('AnimationFinished', onAnimationFinished);
      }

      diagram.addDiagramListener('AnimationFinished', onAnimationFinished);

      animation.start();
      diagram.commitTransaction('animate visibility');

      const chieldNode = node.findTreeChildrenNodes();
      const isCollapse = node.data.isCollapse ? node.data.isCollapse : false;

      if (chieldNode && isCollapse === false) {
        chieldNode.each((node) => {
          nodeCollapseOrExpand(node, visible);
        });
      }

      if (!visible) {
        const nodeToggleButton = node.findObject('nodeToggleButton');
        nodeToggleButton.source = '/assets/images/show.svg';

        node.diagram.model.setDataProperty(node.data, 'isCollapse', true);
      }
    }

    function expandNode(node) {
      return new Promise((resolve) => {
        if (!node) {
          resolve();
          return;
        }

        const nodeToggleButtonWrapper = node?.findObject('nodeToggleButtonWrapper');
        const nodeToggleButton = node?.findObject('nodeToggleButton');

        node.data.isCollapse = false;
        node.diagram.model.setDataProperty(node.data, 'visible', true);

        if (nodeToggleButtonWrapper && nodeToggleButton) {
          nodeToggleButtonWrapper.opacity = 1;
          nodeToggleButton.source = '/assets/images/hide.svg';
        }

        const chieldNode = node.findTreeChildrenNodes();
        chieldNode.each((childNode) => {
          node.diagram.model.setDataProperty(childNode.data, 'visible', true);
        });

        // Resolve the promise once all actions are complete
        resolve();
      });
    }

    function adjustTextBlockHeight(node) {
      const body = node.findObject('body');
      const information = node.findObject('information');
      // const textBlock = node.findObject('textPanel');
      const textCollapse = node.findObject('textCollapse');
      const { measuredBounds } = body;

      if (body.maxSize.height === Infinity || information.maxSize.height === Infinity) {
        textCollapse.margin = new go.Margin(0, 0, 0, 0);
        textCollapse.text = 'Show more';

        // textBlock.maxSize = new go.Size(measuredBounds.width, 150);
        body.maxSize = new go.Size(measuredBounds.width, 92);
        information.maxSize = new go.Size(measuredBounds.width, 92);
      } else {
        textCollapse.margin = new go.Margin(5, 0, 0, 0);
        textCollapse.text = 'Show less';

        // textBlock.maxSize = new go.Size(measuredBounds.width, NaN);
        body.maxSize = new go.Size(measuredBounds.width, NaN);
        information.maxSize = new go.Size(measuredBounds.width, NaN);
      }
      if (node.data.isMerged) {
        const secondNode = node.findObject('secondNode');
        const body = secondNode.findObject('body');
        const information = secondNode.findObject('information');
        const { measuredBounds } = body;
        if (body.maxSize.height === Infinity || information.maxSize.height === Infinity) {
          secondNode.maxSize = new go.Size(measuredBounds.width, 150);
          body.maxSize = new go.Size(measuredBounds.width, 92);
          information.maxSize = new go.Size(measuredBounds.width, 92);
        } else {
          secondNode.maxSize = new go.Size(measuredBounds.width, NaN);
          body.maxSize = new go.Size(measuredBounds.width, NaN);
          information.maxSize = new go.Size(measuredBounds.width, NaN);
        }
      }
    }

    function handleShowActionsMenu(object) {
      if (workSpaceAccess?.data?.role !== 'editor' || !object) return;

      const node = object.part;

      const button = node.findObject('moreActionsMenuButtonPicture');
      const menu = node.findObject('moreActionsMenu');

      if (menu.height !== 0 && menu.height !== 200) return;

      // Button Animation
      var animation = new go.Animation();
      animation.easing = go.Animation.EaseOutExpo;
      animation.duration = 500;

      if (menu.height === 0) {
        animation.add(button, 'angle', 0, 45);
      } else {
        animation.add(button, 'angle', 45, 0);
      }

      animation.start();

      // Action Menu Animation
      var animation = new go.Animation();
      animation.easing = go.Animation.EaseOutExpo;
      animation.duration = 500;

      if (menu.height === 0) {
        animation.add(menu, 'height', 0, 200);
      } else {
        animation.add(menu, 'height', 200, 0);
      }

      animation.start();
    }

    function handleDeleteNode(object) {
      if (workSpaceAccess?.data?.role !== 'editor' || !object) return;

      const node = object.part;

      const loader = node.findObject('loader');

      if (node.findTreeChildrenNodes().count <= 0 && !loader.visible) {
        emitUpdateActionNodes(node.data.key, 'add');

        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.isConfirmed) {
            diagram.remove(node);
            save(true);

            emitCreateUpdateNodes({ nodeId: node.data.key }, 'delete');
          }

          emitUpdateActionNodes(node.data.key, 'remove');
        });
      }
    }

    function handleOpenNodeCommentModel(object) {
      if (workSpaceAccess?.data?.role !== 'editor' || !object) return;

      const node = object.part;
      const { diagram } = node;

      diagram.startTransaction('highlight');
      diagram.clearHighlighteds();
      handleCommentModel(object);
      diagram.commitTransaction('highlight');
    }

    // this is used to determine feedback during drags
    function mayWorkFor(node1, node2) {
      const iconNode1 = node1.findObject('Picture');
      const iconNode2 = node2.findObject('Picture');
      if (
        iconNode1?.source === '/assets/images/add-orange.svg' ||
        iconNode2?.source === '/assets/images/add-orange.svg' ||
        iconNode1?.source === '/assets/images/list.svg' ||
        iconNode2?.source === '/assets/images/list.svg' ||
        iconNode1?.source === '/assets/images/question.svg' ||
        iconNode2?.source === '/assets/images/question.svg'
      ) {
        return false;
      }
      if (node1.findTreeChildrenNodes().count > 0 || node2.findTreeChildrenNodes().count > 0) return false; // must be a Node
      if (!(node1 instanceof go.Node)) return false; // must be a Node
      if (node1 === node2) return false; // cannot work for yourself
      if (node2.isInTreeOf(node1)) return false; // cannot work for someone who works for you
      if (node2.data.isMerged === true || node1.data.isMerged === true) return false;
      return true;
    }
    // This function provides a common style for most of the TextBlocks.
    // Some of these values may be overridden in a particular TextBlock.
    function textStyle() {
      return { font: '9pt poppins, Helvetica, Arial, sans-serif', stroke: 'white' };
    }

    // This converter is used by the Picture.
    function findHeadShot(icon) {
      if (!icon || icon === 'home.svg') return '/assets/images/goal.svg';
      var result = `/assets/images/${icon}`;
      return result;
    }

    function load() {
      getMindMap.mutate(workspaceId, {
        enabled: !!diagram,
        onSuccess: (data) => {
          workspaceTitle.current = data?.data?.title || null;
          const jsonOutput = data.data.output;
          const decodedJson = JSON.parse(jsonOutput);

          if (decodedJson.nodeDataArray.length > 1) {
            setScriptCounter(decodedJson.nodeDataArray.length);
          }

          const firstNodeTitle = decodedJson?.nodeDataArray?.find((node) => node.key === 1)?.main || '';

          diagram.model = go.Model.fromJson(jsonOutput);
          document.getElementById('area').value = data.data.area;
          document.getElementById('prompt').value = `${firstNodeTitle}`;
          document.getElementById('promptBody').value = `${data.data.information || data.data.body}`;
          document.getElementById('token').value = data.data.token;
          document.getElementById('context0').value = data.data.context || '';
          document.getElementById('attributes').value = JSON.stringify(data.data.attributes || '');
          document.getElementById('description').value = data.data.description || '';

          diagram.nodes.each(function (n) {
            const body = n.findObject('body');
            const title = n.findObject('NAMETB');
            const information = n.findObject('information');

            if (n.data.body !== undefined && n.data.body !== null) {
              body.visible = true;

              const icon = n.findObject('Picture');
              if (icon.source === '/assets/images/add-orange.svg') {
                body.editable = true;
                body.isActionable = true;

                title.editable = true;
                title.isActionable = true;
              }
            }

            if (n.data.information !== undefined && n.data.information !== null) {
              information.visible = true;
            }

            if (n.data.examples !== undefined) {
              const textBlock = n.findObject('textPanel');
              textBlock.maxSize = new go.Size(NaN, NaN);
              textBlock.minSize = new go.Size(NaN, NaN);

              const list = n.findObject('examples');
              list.visible = true;
            }
          });

          var { status = 0 } = data.data;
          var firstNode = diagram.findNodeForKey('1');

          const textBlock = firstNode.findObject('textPanel');
          textBlock.maxSize = new go.Size(NaN, NaN);
          textBlock.minSize = new go.Size(NaN, 162);

          if (status === 0 && authToken && authToken !== null) {
            var prompt = firstNodeTitle;
            loadPythonScript(
              prompt,
              'IDEA',
              firstNode,
              decodedJson.nodeDataArray.length > 1 ? decodedJson.nodeDataArray.length : 0,
              true,
            );
          }

          // make sure new data keys are unique positive integers
          let lastkey = 1;
          diagram.model.makeUniqueKeyFunction = (model, data) => {
            let k = data.key || lastkey;
            while (model.findNodeDataForKey(k)) k++;
            data.key = k;
            lastkey = k;
            return k;
          };
        },
      });
    }

    setTimeout(() => {
      load();
    }, 500);
    setObserved(diagram);
    return diagram;
  };

  const getMindMap = useMutation(getMindMapApi);
  const getMindMapExport = useMutation(getMindMapExportApi);
  const getMindMapResultsObj = useMutation(getMindMapResults);

  // Setup zoom in buttonj
  function zoomIn() {
    const diagram2 = diagramRef.current.getDiagram();
    diagram2.commandHandler.increaseZoom();
  }

  // Setup zoom out button
  function zoomOut() {
    const diagram2 = diagramRef.current.getDiagram();
    diagram2.commandHandler.decreaseZoom();
  }

  // Setup zoom to fit button
  function zoomToFit() {
    const diagram2 = diagramRef.current.getDiagram();
    diagram2.padding = new go.Margin(250, 15, 15, 15);
    diagram2.commandHandler.zoomToFit();
  }

  // Setup center Root button
  function centerRoot() {
    const diagram2 = diagramRef.current.getDiagram();
    diagram2.scale = 1;
    diagram2.commandHandler.scrollToPart(diagram2.findNodeForKey(1));
  }

  function clearDiagram() {
    if (disabledNodes.length <= 0 && diagramRef.current && diagramRef.current !== 'TestGraph') {
      const diagram = diagramRef.current.getDiagram();
      const nodesData = diagram.model.nodeDataArray.map((nodeData) => nodeData.key);

      emitUpdateActionNodes(nodesData, 'add');

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, clear it!',
      }).then((result) => {
        emitUpdateActionNodes(nodesData, 'remove');

        if (result.isConfirmed) {
          document.getElementById('token').value = null;
          document.getElementById('context').value = null;

          var nodes = diagram.model.nodeDataArray.filter((node) => node.key !== 1);
          if (nodes) diagram.model.removeNodeDataCollection(nodes);

          save();
          showMenu();
          zoomToFit();
          centerRoot();

          emitCreateUpdateNodes(null, 'clearAll');
        }
      });
    }
  }

  function getAllParentIdeas(node) {
    var parentChain = [];
    node = node.findTreeParentNode();

    while (node !== null) {
      parentChain.unshift(`"${node.findObject('NAMETB').text}"`);
      node = node.findTreeParentNode();
    }

    parentChain.reverse();
    document.getElementById('heritage').value = `[${parentChain}]`;
    // alert(document.getElementById("heritage").val());
  }

  function generatePdf() {
    if (diagramRef.current) {
      const diagram2 = diagramRef.current.getDiagram();
      diagram2.nodes.each((n) => {
        n.isHighlighted = true;
        n.isExpportFlag = true;
      });
      setShowMenuAnimDir(showMenuAnimDir === 1 ? -1 : 1);
      setShowMenuState(!showMenuState);
      setIsAllSelected(false);
      setShowExportModel(true);
    }
  }

  function exportSummary() {
    setShowExportLoader(true);
    const diagram2 = diagramRef.current.getDiagram();
    const exportNodes = [];
    diagram2.nodes.each((n) => {
      if (n.isHighlighted === false && n?.data?.main !== 'ACTION STEPS' && n?.data?.main !== 'EXAMPLES') {
        const nodeObj = {
          main: n.data.main,
          body: n.data.information || n.data.body,
        };

        const children = n.findTreeChildrenNodes();
        children.each((exNode) => {
          if (exNode?.data?.main === 'ACTION STEPS' || exNode?.data?.main === 'EXAMPLES') {
            nodeObj.steps = JSON.stringify(exNode?.data?.examples);
          }
        });
        exportNodes.push(nodeObj);
      }
      n.isExpportFlag = false;
    });
    // console.log('exportNodes', exportNodes);
    // return false;
    getMindMapResultsExportObj.mutate(
      {
        ideas: exportNodes,
        title: document.getElementById('prompt').value,
        workspaceId,
      },
      {
        onSuccess: async (data) => {
          const url = data.file_name;

          const fileName = decodeURIComponent(new URL(url).pathname.split('/').pop().replace(/\+/g, ' '));
          await axios
            .get(url, {
              responseType: 'blob',
            })
            .then((res) => {
              fileDownload(res.data, fileName);
              setShowExportModel(false);
              setShowExportLoader(false);
              const diagram2 = diagramRef.current.getDiagram();
              diagram2.clearHighlighteds();
              toast.success('File downloaded successfully.', TOAST_CONFIG);
            });
        },
        onError: async (data) => {
          setShowExportModel(false);
          setShowExportLoader(false);
          const diagram2 = diagramRef.current.getDiagram();
          diagram2.clearHighlighteds();
          toast.error('Something went wrong, please try again later.', TOAST_CONFIG);
        },
      },
    );
  }

  function exportSelectAll(checked) {
    const diagram2 = diagramRef.current.getDiagram();
    diagram2.nodes.each((n) => {
      if (!(n.part instanceof go.Link) && n.part.data.icon === 'idea.svg') {
        n.isHighlighted = !checked;

        if (checked) {
          const childExamples = n.findTreeChildrenNodes();
          childExamples.each((n) => {
            if (n?.data?.main === 'ACTION STEPS' || n?.data?.main === 'EXAMPLES') {
              n.isHighlighted = false;
            }
          });
        } else if (!checked) {
          const childExamples = n.findTreeChildrenNodes();
          childExamples.each((n) => {
            if (n?.data?.main === 'ACTION STEPS' || n?.data?.main === 'EXAMPLES') {
              n.isHighlighted = true;
            }
          });
        }
      }
    });

    setIsAllSelected(checked);
  }

  function generatePdfCall() {
    var ideas = [];
    const myDiagram = diagramRef.current.getDiagram();
    var title = document.getElementById('prompt').value;

    myDiagram.nodes.each((n) => {
      const ideaText = n.findObject('NAMETB');
      var idea = ideaText.text;

      if (idea != 'MORE INFORMATION' && idea != 'EXAMPLES' && idea != 'ACTION STEPS') {
        getAllParentIdeas(n);
        var heritage = document.getElementById('heritage').value;
        var item = `["${idea}", ${heritage}]`;
        ideas.push(item);
      }
    });

    getMindMapExport.mutate(
      { title, ideas },
      {
        onSuccess: (data) => {
          console.log('download', data);
          // const link = document.createElement('a');
          // link.href = `{{ asset('summary_pdf') }}` + `/${data.output}`;
          // // console.log(link.href);
          // link.download = 'summary.pdf';
          // link.click();
        },
      },
    );
  }

  function exportDiagramImage() {
    if (!diagramRef.current || diagramRef.current === 'TestGraph' || !socketRef.current) return;

    setShowExportLoader(true);

    const diagram = diagramRef.current.getDiagram();
    const workSpaceId = workSpaceAccess?.data?.work_space?.id;

    diagram.padding = new go.Margin(50, 50, 50, 50);

    const bounds = diagram.documentBounds;
    const scale = 3;
    const padding = 50;

    const totalWidth = (bounds.width + 2 * padding) * scale;
    const totalHeight = (bounds.height + 2 * padding) * scale;

    var options = {
      scale,
      maxSize: new go.Size(totalWidth, totalHeight),
      size: new go.Size(totalWidth, totalHeight),
      position: new go.Point(bounds.x - padding, bounds.y - padding),
      background: '#09091d',
      showTemporary: true,
      returnType: 'blob',
      callback: async (imageData) => {
        var url = window.URL.createObjectURL(imageData);

        var a = document.createElement('a');
        a.href = url;
        a.download = `diagram-${workSpaceId}-${moment().format('DDMMYYYYHHmmss')}.png`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        setShowExportLoader(false);
        toast.success('Image exported successfully.', TOAST_CONFIG);
      },
    };

    diagram.makeImageData(options);
  }

  // Show the diagram's model in JSON format
  function save(isAutoSync = false, outputData = null) {
    if (disabledNodes.length <= 0) {
      const diagram2 = diagramRef.current.getDiagram();
      document.getElementById('mySavedModel').value = diagram2.model.toJson();
      diagram2.isModified = false;

      var output = outputData !== null ? outputData : diagram2.model.toJson();
      var token = document.getElementById('token').value;
      var context = document.getElementById('context0').value;

      savedWorkSpaceMutate(
        {
          id: workspaceId,
          title: workspaceTitle?.current || '',
          output,
          area: workSpaceAccess?.data?.work_space?.area || '',
          token,
          context,
        },
        {
          onSuccess: (data) => {
            if (isAutoSync) {
              // toast.success('Data synced successfully.', TOAST_CONFIG);
            } else {
              // toast.success(data.message, TOAST_CONFIG);
            }
          },
        },
      );
    }
  }

  const [showMenuState, setShowMenuState] = useState(false);
  const [showMenuAnimDir, setShowMenuAnimDir] = useState(-1);

  function showMenu() {
    setShowMenuAnimDir(showMenuAnimDir === 1 ? -1 : 1);
    setShowMenuState(!showMenuState);
  }

  // Socket event emiiter for update room action nodes data
  const emitUpdateActionNodes = (nodesData, action) => {
    const socket = socketRef.current;
    const workSpaceId = workSpaceAccess?.data?.work_space?.id;

    if (socket && workSpaceId) {
      const data = [];

      if (Array.isArray(nodesData)) {
        nodesData.forEach((nodeData) =>
          data.push({
            userId: socket.id, // User Id (it's a socket connection id for identify the action user) (required)
            nodeId: nodeData, // Node Id (it's a node key for get node data by key) (required)
          }),
        );
      } else {
        data.push({
          userId: socket.id, // User Id (it's a socket connection id for identify the action user) (required)
          nodeId: nodesData, // Node Id (it's a node key for get node data by key) (required)
        });
      }

      socket.emit('updateRoomData', {
        roomId: workSpaceId, // Room Id (We have used Workspace Id as Room ID)
        data, // Array of node data object (User Id, Node Id)
        action, // Action - add (for add action node to room data), remove (for remove action node from room data)
      });
    }
  };

  // Socket event emiiter for create/update node
  const emitCreateUpdateNodes = (data, action) => {
    const socket = socketRef.current;
    const workSpaceId = workSpaceAccess?.data?.work_space?.id;

    if (socket && workSpaceId)
      socket.emit('createUpdateNodes', {
        roomId: workSpaceId, // Room Id (We have used Workspace Id as Room ID)
        data, // nodeId (required), category (node category), data (node's updated data), data is null when action is clearAll
        action, // Action - create, delete, update, clearAll, mergeNode (for update nodes in active workspace)
      });
  };

  const handleCreateUpdateNodes = ({ data, action }) => {
    if (diagramRef.current && diagramRef.current !== 'TestGraph') {
      const diagram = diagramRef.current.getDiagram();

      if (action === 'create') {
        const node = diagram.findNodeForKey(data.nodeId);
        if (!node) return;

        const { isCollapse } = node.data;

        if (data.category === 'IDEA') {
          const nodeData = JSON.parse(data.data);

          // Start a transaction for adding employees
          if (!isCollapse) diagram.startTransaction('add employee');

          // Iterate through the node data array
          // eslint-disable-next-line no-restricted-syntax
          for (const item of nodeData) {
            const thisemp = node.data;
            const newemp = { main: item.main, information: item.body, icon: 'idea.svg', parent: thisemp.key };
            if (isCollapse) newemp.visible = false;

            // Add the new employee node data to the diagram
            diagram.model.addNodeData(newemp);

            // Find the newly created node and set its location and visibility
            const newnode = diagram.findNodeForData(newemp);
            if (newnode) {
              newnode.location = node.location;
              const body = newnode.findObject('body');
              if (body) body.visible = true;

              if (!isCollapse) diagram.commandHandler.scrollToPart(newnode);

              disableNodeActionButton(newnode);
            }
          }

          // Commit the transaction for adding employees
          if (!isCollapse) diagram.commitTransaction('add employee');
        } else if (data.category === 'INFO') {
          const modifiedStr = data.data
            .replace(/"{"main":/g, '')
            .replace(/"body":/g, '')
            .replace(/"}/g, '')
            .replace(/\\"/g, '"')
            .replace(/\\'/g, "'");

          const parsedData = JSON.parse(modifiedStr);
          const paragraphs = parsedData.join('\n');

          const thisemp = node.data;
          if (!isCollapse) diagram.startTransaction('add employee');
          const newemp = {
            main: 'MORE INFORMATION',
            information: paragraphs,
            icon: 'question.svg',
            parent: thisemp.key,
          };
          if (isCollapse) newemp.visible = false;
          diagram.model.addNodeData(newemp);

          const newnode = diagram.findNodeForData(newemp);
          if (newnode) {
            newnode.location = node.location;
            const information = newnode.findObject('body');
            information.visible = true;

            if (!isCollapse) diagram.commandHandler.scrollToPart(newnode);

            disableNodeActionButton(newnode);
          }
          if (!isCollapse) diagram.commitTransaction('add employee');
        } else if (data.category === 'EXAMPLE') {
          let modifiedStr = data.data;

          if (
            !modifiedStr.includes('{"main": "*", "body": ""}') &&
            !modifiedStr.includes('{"main": "*", "body": " "}')
          ) {
            modifiedStr = modifiedStr
              .replace(/\{"main": \*, "body":/g, '')
              .replace(/{"main": "*", "body":"/g, '')
              .replace(/"}"}/g, '"}')
              .replace(/" "/g, '"')
              .replace(/""/g, '"')
              .replace(/\\'/g, "'");
          }

          const parsedData = JSON.parse(modifiedStr);
          const examplesArray = parsedData.map((item) => item.body);
          const main = parsedData.length ? parsedData[0].main : null;

          if (!isCollapse) diagram.startTransaction('add employee');

          const thisemp = node.data;
          const newemp = { main: 'ACTION STEPS', icon: 'list.svg', parent: thisemp.key, examples: examplesArray };
          if (isCollapse) newemp.visible = false;
          diagram.model.addNodeData(newemp);

          const newnode = diagram.findNodeForData(newemp);
          if (newnode) {
            newnode.location = node.location;
            const examples = newnode.findObject('examples');
            if (examples) examples.visible = true;

            const textBlock = newnode.findObject('textPanel');
            if (textBlock) {
              textBlock.maxSize = new go.Size(NaN, NaN);
              textBlock.minSize = new go.Size(NaN, NaN);
            }

            if (!isCollapse) diagram.commandHandler.scrollToPart(newnode);

            disableNodeActionButton(newnode);
          }

          if (!isCollapse) diagram.commitTransaction('add employee');
        } else if (data.category === 'CUSTOM') {
          if (!isCollapse) diagram.startTransaction('add employee');

          const newNodeData = data.data;
          if (isCollapse) newNodeData.visible = false;

          diagram.model.addNodeData(newNodeData);
          const newnode = diagram.findNodeForData(newNodeData);
          if (newnode) newnode.location = node.location;

          const body = newnode.findObject('body');
          body.visible = true;
          body.editable = true;
          body.isActionable = true;

          const title = newnode.findObject('NAMETB');
          title.editable = true;
          title.isActionable = true;

          if (!isCollapse) {
            diagram.commitTransaction('add employee');
            diagram.commandHandler.scrollToPart(newnode);
          }

          disableNodeActionButton(newnode);
        }

        const nodeToggleButtonWrapper = node?.findObject('nodeToggleButtonWrapper');
        if (nodeToggleButtonWrapper) nodeToggleButtonWrapper.opacity = 1;
      } else if (action === 'delete') {
        const node = diagram.findNodeForKey(data.nodeId);
        if (!node) return;

        diagram.remove(node);
      } else if (action === 'update') {
        const { model } = diagram;

        model.startTransaction('update node data');

        const nodeData = model.findNodeDataForKey(data.nodeId);
        if (nodeData) {
          model.set(nodeData, 'main', data.data.main);
          model.set(nodeData, 'body', data.data.body);
        }

        model.commitTransaction('update node data');
      } else if (action === 'clearAll') {
        document.getElementById('token').value = null;
        document.getElementById('context').value = null;

        var nodesData = diagram.model.nodeDataArray.filter((node) => node.key !== 1);
        diagram.model.removeNodeDataCollection(nodesData);

        zoomToFit();
        centerRoot();
      } else if (action === 'mergeNode') {
        const node = diagram.findNodeForKey(data.nodeId);
        const secondNode = diagram.findNodeForKey(data.data.key);
        if (!node || !secondNode) return;

        node.data.second = data.data;

        diagram.model.setDataProperty(node.data, 'isMerged', true);
        if (data.data.main) diagram.model.setDataProperty(node.data, 'secondMain', data.data.main);
        if (data.data.body) diagram.model.setDataProperty(node.data, 'secondBody', data.data.body);
        if (data.data.information) diagram.model.setDataProperty(node.data, 'secondInformation', data.data.information);

        diagram.remove(secondNode);
      }
    }
  };

  const disableNodeActionButton = (node) => {
    if (!node) return;

    if ((authToken && authToken !== '' && workSpaceAccess?.data?.role !== 'editor') || !authToken || authToken === '') {
      // const generate = node.findObject('generate');
      // generate.visible = false;

      // const customNodeBtn = node.findObject('customNodeBtn');
      // customNodeBtn.visible = false;

      const refreshExampleButton = node.findObject('refreshExampleButton');
      refreshExampleButton.visible = false;
    }
  };

  const handleUpdateRoomData = async (roomData, oldRoomData) => {
    if (!diagramRef.current || diagramRef.current === 'TestGraph' || !socketRef.current) return;

    const socketId = socketRef.current.id;
    const nodesData = roomData?.filter((n) => n.userId !== socketId) || [];
    const isLoaderDisable = !!oldRoomData?.some((n) => n.userId !== socketId && n.nodeId === 1);

    setDisabledNodes(nodesData);

    const diagram = diagramRef.current.getDiagram();
    const { nodeDataArray } = diagram.model;

    nodeDataArray.forEach((nodeData) => {
      const node = diagram.findNodeForKey(nodeData.key);
      if (!node) return;

      const picture = node.findObject('spinner');
      const loader = node.findObject('loader');
      const isNodeDataExists = nodesData.some((n) => n.nodeId === nodeData.key);
      const shouldStopAnimation = (nodeData.key === 1 && isLoaderDisable) || nodeData.key !== 1;

      if (isNodeDataExists) {
        if (picture) {
          const animation = new go.Animation();
          animation.add(picture, 'angle', picture.angle, 360);
          animation.runCount = Infinity;
          animation.start();
        }
        if (loader) loader.visible = true;
      } else if (shouldStopAnimation) {
        if (picture && picture.animation && !picture.animation.isCompleted) {
          picture.animation.stop();
        }
        if (loader) loader.visible = false;
      }
    });
  };

  useEffect(() => {
    const workSpaceId = workSpaceAccess?.data?.work_space?.id;

    if (!workSpaceId && socketRef.current) return;

    // New WebSocket connection
    const newSocket = io(process.env.REACT_APP_SOCKET_URL);
    socketRef.current = newSocket;

    // Join the socket room
    newSocket.emit('joinRoom', { roomId: workSpaceId });

    // Listener for get updated room data
    newSocket.on('updateRoomData', handleUpdateRoomData);

    // Listener for create/update nodes data
    newSocket.on('createUpdateNodes', handleCreateUpdateNodes);

    // Cleanup function to leave the socket room
    return () => {
      newSocket.emit('leaveRoom', { roomId: workSpaceId });
      newSocket.off('updateRoomData');
      newSocket.off('createUpdateNodes');
      newSocket.disconnect();
      socketRef.current = null;
    };
  }, [workSpaceAccess]);

  if (isLoadingWorkspaceAccess) {
    return (
      <div className="spinner-div loading-container-height-100vh">
        <Spinner animation="border" className="spiner" />
      </div>
    );
  }

  return (
    <div className="mindMamppage">
      {showExportModel === true ? <div className="exportIdeasTitle">Select the ideas you want to export</div> : ''}
      {showExportLoader === true ? (
        <div className="main-spinner">
          <img src="/assets/images/spinner.svg" style={{ opacity: 1 }} alt="test" />
        </div>
      ) : (
        ''
      )}

      {showCommentModel === true ? (
        <div ref={commentModelRef}>
          <CommentModal commentNodeData={commentNodeData} handleClose={handleCommentModel} />
        </div>
      ) : (
        ''
      )}
      {showExportModel === true ? (
        <div className="exportContainer">
          <div className="export-select-all-checkbox-wrapper" onClick={() => exportSelectAll(!isAllSelected)}>
            {isAllSelected ? <FaCheckSquare className="checked" /> : <FaRegSquare className="unchecked" />}

            <span>Select All</span>
          </div>

          <button type="button" className="open-btn" onClick={exportSummary}>
            Export <MdDownload />
          </button>
        </div>
      ) : (
        ''
      )}

      <TopNavigationBar
        isShowCompanyDropdown={false}
        logoLink={routes.dashBoard}
        isShowTrialAlert={!!(authToken && authToken !== '')}
        isShowToggleDropdown={!!(authToken && authToken !== '')}
        isShowProcessingAlert={!!(authToken && authToken !== '')}
        processingAlertText={isLoadingWorkspaceMutate ? 'Saving...' : 'Saved'}
      />

      <div className="mindmap_edit-page-workspace-title">
        {isTitleEditing && authToken && authToken !== '' && workSpaceAccess?.data?.role === 'editor' ? (
          <input
            type="text"
            placeholder="Enter Workspace Title"
            defaultValue={workspaceTitle?.current}
            onBlur={handleBlurWorkspaceTitle}
            autoFocus
          />
        ) : (
          <h1 onClick={handleSetWorkspaceTitleEditing}>{workspaceTitle?.current}</h1>
        )}
      </div>

      <div className="mind-map-containers">
        {socketRef.current && (
          <ReactDiagram initDiagram={initDiagram} ref={diagramRef} divClassName="diagram-component" />
        )}

        {showExportModel === false ? (
          <div className="btn-container">
            <div className="btn-container1">
              <div
                className={`menu1 ${
                  // eslint-disable-next-line no-nested-ternary
                  showMenuState
                    ? authToken && authToken !== '' && workSpaceAccess?.data?.role === 'editor'
                      ? 'expanded'
                      : 'viewer-expanded'
                    : ''
                }`}
                id="menu"
              >
                <OverlayTrigger placement="left" overlay={<Tooltip>Zoom in</Tooltip>}>
                  <span
                    className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                    id="zoomIn"
                    onClick={zoomIn}
                    alt="Zoom In"
                  >
                    <MdZoomIn />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="left" overlay={<Tooltip>Zoom out</Tooltip>}>
                  <span
                    className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                    id="zoomOut"
                    onClick={zoomOut}
                  >
                    <MdZoomOut />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="left" overlay={<Tooltip>Fit to screen</Tooltip>}>
                  <span
                    className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                    id="zoomToFit"
                    onClick={zoomToFit}
                  >
                    <MdFitScreen />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="left" overlay={<Tooltip>Center</Tooltip>}>
                  <span
                    className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                    id="centerRoot"
                    onClick={centerRoot}
                  >
                    <MdFullscreenExit />
                  </span>
                </OverlayTrigger>
                {authToken && authToken !== '' && workSpaceAccess?.data?.role === 'editor' && (
                  <>
                    <OverlayTrigger placement="left" overlay={<Tooltip>Export PNG</Tooltip>}>
                      <span
                        className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                        id="centerRoot"
                        onClick={exportDiagramImage}
                      >
                        <MdImage />
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip>Clear all</Tooltip>}>
                      <span
                        className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                        id="clear"
                        onClick={clearDiagram}
                        style={{ fontSize: '17px', marginLeft: '-17.5px', marginTop: '0.5px', marginBottom: '0.5px' }}
                      >
                        <MdBackspace />
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip>Export</Tooltip>}>
                      <span
                        className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                        id="export"
                        style={{ fontSize: '17px', marginLeft: '-17.5px', marginTop: '0.5px', marginBottom: '0.5px' }}
                        onClick={generatePdf}
                      >
                        <MdDownload />
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip>Save</Tooltip>}>
                      <span
                        className={`material-icons-round ${showMenuState ? '' : 'hidden'}`}
                        id="SaveButton"
                        onClick={save}
                      >
                        <MdSave />
                      </span>
                    </OverlayTrigger>
                  </>
                )}
              </div>
            </div>
            <div className="menu1" onClick={(e) => showMenu()}>
              <div className="menu-icon">
                <div className="container container-icon-section">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: animationMenu,
                    }}
                    className="toggle"
                    style={{ margin: 'auto' }}
                    height={40}
                    width={45}
                    direction={showMenuAnimDir}
                    id="menu-animation"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <input type="number" id="token" name="token" defaultValue="" hidden />
        <input type="text" id="context" name="context" defaultValue="" hidden />
        <input type="text" id="context0" name="context0" defaultValue="" hidden />
        <input type="text" id="context1" name="context1" defaultValue="" hidden />
        <input type="text" id="area" name="area" defaultValue="" hidden />
        <input type="text" id="prompt" defaultValue="" hidden />
        <input type="text" id="promptBody" defaultValue="" hidden />
        <input type="text" id="heritage" defaultValue="" hidden />
        <input type="text" id="attributes" defaultValue="" hidden />
        <input type="text" id="description" defaultValue="" hidden />
        <textarea id="mySavedModel" hidden />
      </div>
    </div>
  );
};

export default MindMapEdit;
