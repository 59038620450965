import React from 'react';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';

const NodeGroups = () => {
  const initDiagram = () => {
    const $ = go.GraphObject.make;
    const diagram = $(go.Diagram, {
      'undoManager.isEnabled': true,
      model: $(go.GraphLinksModel, {
        linkKeyProperty: 'key',
      }),
    });

    diagram.nodeTemplate = $(
      go.Node,
      'Auto',
      {
        height: 200,
        width: 200,
      },

      $(go.Shape, 'RoundedRectangle', {
        fill: '#09091d',
        strokeWidth: 0,
        parameter1: 10,
        height: 200,
        width: 200,
        shadowVisible: true,
      }),

      $(
        go.TextBlock,
        {
          font: 'bold 11pt poppins-semibold, Helvetica, Arial, sans-serif',
          textAlign: 'center',
          stroke: '#F05436',
          wrap: go.TextBlock.WrapFit,
          overflow: go.TextBlock.OverflowEllipsis,
        },
        new go.Binding('text', 'data', (data, obj) => {
          return data.title || '';
        }),
      ),

      // $(
      //   go.TextBlock,
      //   { font: 'bold 9pt poppins, Helvetica, Arial, sans-serif', textAlign: 'left', stroke: '#fff' },
      //   new go.Binding('text', 'data', (data, obj) => {
      //     return data.description || '';
      //   }),
      // ),
    );

    return diagram;
  };

  const handleModelChange = (changes) => {
    console.log('GoJS model changed!', changes);
  };

  return (
    <div
      style={{
        background: 'white',
        height: '100vh',
        width: '100vw',
      }}
    >
      <ReactDiagram
        initDiagram={initDiagram}
        divClassName="diagram-component"
        nodeDataArray={[
          { key: 1, parent: 1, data: { title: 'Node 1', description: 'Description 1' } },
          { key: 2, parent: 2, data: { title: 'Node 2', description: 'Description 2' } },
          { key: 3, parent: 1, data: { title: 'Node 3', description: 'Description 3' } },
          { key: 4, parent: 2, data: { title: 'Node 4', description: 'Description 4' } },
        ]}
        linkDataArray={
          [
            // { key: -1, from: 1, to: 3 },
            // { key: -2, from: 2, to: 4 },
            // { key: -3, from: 2, to: 2 },
            // { key: -4, from: 3, to: 4 },
            // { key: -5, from: 4, to: 1 },
          ]
        }
        onModelChange={handleModelChange}
      />
    </div>
  );
};

export default NodeGroups;
